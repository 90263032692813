import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import btcQR from '../../assets/images/bitcoin1_legacy.png';
import ethQR from '../../assets/images/ethereum_legacy.png';
import xrpQR from '../../assets/images/ripple_xrp.png';

import "./Donate.css";


const Donate = () => {

    function writeText(str) {
        return new Promise(function(resolve, reject) {
      
          /********************************/
          var range = document.createRange();
          range.selectNodeContents(document.body);
          document.getSelection().addRange(range);
          /********************************/
      
          var success = false;
          function listener(e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
            success = true;
          }
          document.addEventListener("copy", listener);
          document.execCommand("copy");
          document.removeEventListener("copy", listener);
      
          /********************************/
          document.getSelection().removeAllRanges();
          /********************************/
      
          success ? resolve(): reject();
        });
      };


    const [showETH, setShowETH] = useState(false);
    const [showBTC, setShowBTC] = useState(false);
    const [showXRP, setShowXRP] = useState(false);
    const handleCloseETH = () => setShowETH(false);
    const handleCloseBTC = () => setShowBTC(false);
    const handleCloseXRP = () => setShowXRP(false);
    const handleShowETH = () => setShowETH(true);
    const handleShowBTC = () => setShowBTC(true);
    const handleShowXRP = () => setShowXRP(true);

    return(
        <div className="DonateContainer">
            <div className="DonateContentContainer">
                <div className="TextTitle">
                    <h2>Donate</h2>
                </div>
                <div className="TextDescription">
                    We are a small team of programming and education enthusiasts working on code
                    at our homes or in cafes. This is our second project as a group. We are hoping to 
                    throw ourselves into an endeavor that not only can give us a break into entrepreneurial 
                    venture, but that can also offer benefits to the people who use it. While 
                    we have created a lot on our own, our roadmap is leading us to develop more elaborate 
                    (and costly!) features, so any and all support is very much welcome and will be forever 
                    remembered and appreciated. 
                </div>
                <p className="coffeeP">
                    <a href="https://www.buymeacoffee.com/ratchecker" target="_blank" rel="noopener noreferrer">
                        <Button variant="outline-light"> 
                            <span role="img" aria-label="hot beverage">☕</span>
                            Buy Us A Coffee
                        </Button>
                    </a>
                </p>

                <div className="CryptoRow">
                    <Button variant="outline-light" onClick={handleShowETH}>Ethereum</Button>
                        <Modal show={showETH} onHide={handleCloseETH}>
                            <Modal.Header>
                                <Modal.Title><i>ETHEREUM</i></Modal.Title>
                                <button 
                                    onClick={handleCloseETH}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <img alt="Ethereum QR Code" src={ethQR}/>
                                <div class="input-group mb-3">
                                    <Form.Control type="text" value="0x6C7f99ef627bD8e7ea94B9D403C831B6D56D52De" readOnly/>
                                    <div class="input-group-append">
                                        <Button 
                                            variant="outline-dark secondary"
                                            type="button"  
                                            onClick={() => writeText('0x6C7f99ef627bD8e7ea94B9D403C831B6D56D52De')}
                                        >
                                            Copy
                                        </Button>
                                    </div>
                                </div>
                                Ethereum (ETH/ERC20)
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseETH}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        <Button variant="outline-light" onClick={handleShowBTC}>Bitcoin</Button>
                        <Modal show={showBTC} onHide={handleCloseBTC}>
                            <Modal.Header>
                                <Modal.Title><i>BITCOIN</i></Modal.Title>
                                <button 
                                    onClick={handleCloseBTC}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <img alt="Bitcoin QR Code" src={btcQR} width="200px" height="200px"/>
                                <div class="input-group mb-3">
                                    <Form.Control type="text" value="1DjG1Z2UZcd8z21jiKExvXkP9R9LytEeZm" readOnly/>
                                    <div class="input-group-append">
                                        <Button 
                                            variant="outline-dark secondary"
                                            type="button"  
                                            onClick={() => writeText('1DjG1Z2UZcd8z21jiKExvXkP9R9LytEeZm')}
                                        >
                                            Copy
                                        </Button>
                                    </div>
                                </div>
                                Bitcoin (legacy)
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseBTC} style={{ height: "35px" }}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    
                    <Button variant="outline-light" onClick={handleShowXRP}>Ripple</Button>
                        <Modal show={showXRP} onHide={handleCloseXRP}>
                            <Modal.Header>
                                <Modal.Title><i>RIPPLE</i></Modal.Title>
                                <button 
                                    onClick={handleCloseXRP}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close" />
                                    {/* </button> */}
                            </Modal.Header>
                            <Modal.Body>
                                <img alt="Bitcoin QR Code" src={xrpQR} width="200px" height="200px"/>
                                <div class="input-group mb-3">
                                    <Form.Control type="text" value="rpCCbzqvRJ3yCvHdBX3XGdz3k4gRpuFXfX" readOnly/>
                                    <div class="input-group-append">
                                        <Button 
                                            variant="outline-dark secondary"
                                            type="button"  
                                            onClick={() => writeText('rpCCbzqvRJ3yCvHdBX3XGdz3k4gRpuFXfX')}
                                        >
                                            Copy
                                        </Button>
                                    </div>
                                </div>
                                Ripple (XRP)
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseXRP}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        
                </div>

            </div>
        </div>
    )
}

export default Donate;
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../axios-edeco';

import { Button, Form, Modal } from 'react-bootstrap';

import CategoriesPreview from './CategoriesPreview/CategoriesPreview';

import './Categories.css';

const Categories = () => {

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);

    const history = useHistory();

    const [subjectData, setSubjectData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [objSubCat, setObjSubCat] = useState({});
    const [catForEdit, setCatForEdit] = useState([]);
    const [checkedSubject, setCheckedSubject] = useState("");
    const [checkedSubjectDesc, setCheckedSubjectDesc] = useState("");

    const [alertNoData, setAlertNoData] = useState([]);
    const [alertDisplay, setAlertDisplay] = useState(false);
    const [alertEditDesc, setAlertEditDesc] = useState(false);
    const [alertAddCheckSubject, setAlertAddCheckSubject] = useState(false);
    const [alertAddEmptyField, setAlertAddEmptyField] = useState(false);

    const [showEditForm, setShowEditForm] = useState(false);

    const [addName, setAddName] = useState("");
    const [addDescription, setAddDescription] = useState("");

    const [editName, setEditName] = useState("");
    const [editDescription, setEditDescription] = useState("");

    const [showAddWarning, setShowAddWarning] = useState(false);

    const [showEditWarning, setShowEditWarning] = useState(false);
    const [contentsEditWarning, setContentsEditWarning] = useState("");
    const [selectedCategoriesIdx, setSelectedCategoriesIdx] = useState([]);

    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [contentsDeleteWarning, setContentsDeleteWarning] = useState("");

    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const [selectedCategoryPK, setSelectedCategoryPK] = useState([]);

    useEffect(() => {
        axios.get('/api/category/all', 
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            }
        })
        .then(response => {
            const data = response.data
            console.log(data.length)
            if (data.length === 0) {
                console.log("No Categories")
                let alertContents = [
                    <div className="AlertNoSubject">
                        No subject data in database
                    </div>
                ]
                setAlertNoData(alertContents);
                setAlertDisplay(true);
            }
            else {
                let categories = [];
                let allSubjects = [];
                let objSubCat = {};
                for (let i in data) {
                    let iCat = data[i];
                    categories = categories.concat(iCat); // building the category data array
                    console.log("subject.title: " + iCat.subject.title)
                    if (allSubjects.includes(iCat.subject.title)) { // start building the subject/cat Object
                        let iSubjField = objSubCat[iCat.subject.title] // set a temp value for the value array
                        iSubjField = iSubjField.concat(iCat) // don't add subject, append cat to array value @ key 'subject'
                        objSubCat[iCat.subject.title] = iSubjField;
                    }
                    else { // we haven't encountered the subject before
                        allSubjects = allSubjects.concat(iCat.subject.title); //add subject to iSubject
                        objSubCat[iCat.subject.title] = [iCat] // set the key to Subj title and the value to the category card
                    }
                }
                setCategoryData(categories);
                setObjSubCat(objSubCat);
            }
        })

        axios.get('/api/subject/all', 
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            }
        })
        .then(response=> {
            let data = response.data
            setSubjectData(data);
        })
        // }, [userToken, selectedCategoriesPK, selectedCategoriesIdx]);
    }, [userToken, selectedCategoriesIdx]);

    const handleEditNameInput = (event) => {
        console.log(event.target.value)
        setEditName(event.target.value);
    };

    const handleEditDescriptionInput = (event) => {
        console.log(event.target.value)
        setEditDescription(event.target.value)
    };

    const handleAddNameInput = (event) => {
        setAddName(event.target.value);
    };

    const handleAddDescriptionInput = (event) => {
        setAddDescription(event.target.value)
    };

    const toggleAddWarning = () => {
        let updateShowAddWarning = !showAddWarning;
        setShowAddWarning(updateShowAddWarning);
    };

    const handleAddOpen = () => {
        toggleAddWarning();
    };

    // UPDATE TO INCLUDE ALL VARIABLES
    const closeAdd = () => {
        setShowAddWarning(false);
    };

    const toggleEditWarning = () => {
        let updateShowEditWarning = !showEditWarning;
        setShowEditWarning(updateShowEditWarning);
    };

    const toggleEditForm = () => {
        let updateShowEditForm = !showEditForm;
        setShowEditForm(updateShowEditForm);
    };

    const toggleDeleteWarning = () => {
        let updateShowDeleteWarning = !showDeleteWarning;
        setIsLoadingDelete(false);
        setShowDeleteWarning(updateShowDeleteWarning);
    };

    const handleAdd = () => {
        let validationScore = 0;

        if (checkedSubject.length === 0) {
            setAlertAddCheckSubject(true);
        }
        else {
            validationScore = validationScore + 1;
            setAlertAddCheckSubject(false);
        }
        if (addName.length === 0 || addDescription.length === 0) {
            setAlertAddEmptyField(true);
        }
        else {
            validationScore = validationScore + 1;
            setAlertAddEmptyField(false);
        }
        if (validationScore === 2) {
            // hit API
            setIsLoadingAdd(true);
            axios.post('/api/category/create',
            {
                title: addName,
                description: addDescription,
                feature: {
                    title: "JukuX", 
                    description: "Tokorozawa Juku 10",
                    code: "tok_juku_10"
                },
                subject: {
                    title: checkedSubject,
                    description: checkedSubjectDesc,
                    feature: {
                        title: "JukuX",
                        description: "Tokorozawa Juku 10",
                        code: "tok_juku_10"
                    }
                }
            },
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                }
            })
            .then(
                setTimeout(() => {
                    setIsLoadingAdd(false);
                    history.push('/UserHome');
                }, 3000)
            )
        }
    };

    const handleEdit = () => {
        console.log("HI THERE");
        let updateContentsEditWarning = "";
        if (selectedCategoryPK.length === 0) {
            updateContentsEditWarning = "No category selected. Please select one category"
            setContentsEditWarning(updateContentsEditWarning);
            toggleEditWarning();
        }
        else if (selectedCategoryPK.length > 1) {
            updateContentsEditWarning = "Only one category can be edited at a time. Please unselect all but one category"
            setContentsEditWarning(updateContentsEditWarning);
            toggleEditWarning();
        }
        else { //there is exactly one subject selected
            let selectedPK = selectedCategoryPK[0];
            console.log("YES YES YES")
            for (let i in categoryData) {
                let iCat = categoryData[i];
                if (iCat.id === selectedPK) {
                    console.log("iSubj PK: " + iCat.id)
                    console.log("Subject: " + iCat.title)
                    setCatForEdit(iCat);
                    setShowEditForm(true);
                }
            }
        }
    };

    const handleEditFormClose = () => {
        setEditName("");
        setEditDescription("");
        setAlertEditDesc(false);
        setIsLoadingEdit(false);
        toggleEditForm();
    };

    const handleConfirmEdit = () => {
        setIsLoadingEdit(true);
        let updatedEditName = "";
        let updatedEditDesc = "";
        let totalChanges = 0;

        // Update Name values
        if (editName.length === 0) {
            console.log("Nothing in Name field")
            updatedEditName = catForEdit.title
        }
        else {
            console.log("Name Category Has shit")
            updatedEditName = editName;
            totalChanges = totalChanges + 1;
        }
        
        // Update Description values
        if (editDescription.length === 0) {
            console.log("Nothing in Desc field")
            updatedEditDesc = catForEdit.description;
        }
        else {
            console.log("Desc Field has shit")
            updatedEditDesc = editDescription;
            totalChanges = totalChanges + 1;
        }

        // API Call, but if there are no changes to be made, don't call
        if (totalChanges === 0) {
            console.log("No changes")
            handleEditFormClose();
        }
        else {
            console.log("Pinging APIs...")
            axios.patch('/api/category/' + catForEdit.id + '/update',
                {
                    id: catForEdit.id,
                    title: updatedEditName,
                    description: updatedEditDesc
                },
                { headers: 
                    { 
                        'Authorization': 'Token ' + userToken
                    }
                })
                .then(
                    setTimeout(() => {
                        setIsLoadingEdit(false);
                        history.push('/UserHome');
                    }, 3000)
                )
        }
    }

    const handleCategorySelection = (PK) => {
        let updateSelectedCategoryPK = selectedCategoryPK;
        console.log("PK List 1: " + updateSelectedCategoryPK)
        if (updateSelectedCategoryPK.includes(PK)) {
            let indexOfPK = updateSelectedCategoryPK.indexOf(PK);
            if (indexOfPK > -1) {
                updateSelectedCategoryPK.splice(indexOfPK, 1);
                console.log("PK List 2: " + updateSelectedCategoryPK)
                setSelectedCategoryPK(updateSelectedCategoryPK);
            }
        }
        else {
            updateSelectedCategoryPK = updateSelectedCategoryPK.concat(PK);
            console.log("PK List 2: " + updateSelectedCategoryPK);
            setSelectedCategoryPK(updateSelectedCategoryPK);
        }
    }

    const handleRemoveClick = () => {
        let updateContentsDeleteWarning = "";
        if (selectedCategoryPK.length === 0) {
            updateContentsDeleteWarning = "Please select at least one subject to remove"
            setContentsDeleteWarning(updateContentsDeleteWarning);
            toggleDeleteWarning();
        }
        else {
            updateContentsDeleteWarning = "Are you sure you want to delete the following subjects?  Deletions are permanent!";
            setContentsDeleteWarning(updateContentsDeleteWarning);
            toggleDeleteWarning();
        }
    }

    const handleDelete = () => {
        setIsLoadingDelete(true);
        for (let i in selectedCategoryPK) {
            let iPK = selectedCategoryPK[i];
            axios.delete('/api/category/'+iPK+'/delete', 
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                }
            })
            .catch(error=>{
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            })
        }
        setSelectedCategoryPK([]);
        
        setTimeout(() => {
            setShowDeleteWarning(false);
            setIsLoadingDelete(false);
            history.push('/UserHome');
          }, 3000);
    }

    return(
        <div className="CategoriesPageContainer">
            <div className="TextTitle">
                <h2>Categories</h2> 
            </div>
            <div className="SubjectButtons">
                <div className="SubTitle">
                    <span >All Categories</span>
                </div>
                <div className="ButtonGroup">
                    
                    <button onClick={handleAddOpen}>Add</button>
                    <Modal centered show={showAddWarning} onHide={toggleAddWarning}>
                        <Modal.Header>
                            <Modal.Title><i>Add a Category</i></Modal.Title>
                            <button 
                                onClick={closeAdd}
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="AddFormContainer">
                                <div>
                                    Select the subject you would like to add your category to:
                                </div>
                                <hr />
                                {alertAddCheckSubject && 
                                    <div className="NewDataWarning">
                                        Please select a category:
                                    </div>
                                } 
                                <div className="SubjectRadioBoxGrid">
                                {subjectData.map((sub, idx)=>
                                    <Form.Check
                                        key={idx}
                                        inline
                                        label={sub.title}
                                        name="Subjects"
                                        type="radio"
                                        onChange={() => {
                                            setCheckedSubject(sub.title);
                                            setCheckedSubjectDesc(sub.description);
                                        }}
                                    />
                                )}
                                </div>
                                <hr />
                                <div className="NewDataInput">
                                    <div className="NewDataCategoryName">
                                        <div>
                                            New Category Name: 
                                        </div>
                                        <div>
                                            <input onChange={handleAddNameInput}/>
                                        </div>
                                    </div>
                                    <div className="NewDataCategoryDescription">
                                        <div>
                                            New Category Description: 
                                        </div>
                                        <div>
                                            <input onChange={handleAddDescriptionInput}/>
                                        </div>
                                    </div>
                                    {alertAddEmptyField &&
                                        <div className="NewDataWarning">
                                            Please include data in both fields: Category Name and Category Description.
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* {selectedCategoryPK.length>0 &&
                            categoryData.map((c, i)=>{
                                if (selectedCategoryPK.includes(c.id)) {
                                    return(<span key={i} style={{ color: "red"}}>{c.title}</span>)
                                }
                            })
                            } */}
                        </Modal.Body>
                        <Modal.Footer>
                        <div className="LoadingContainer1" style={{ margin: "0px 150px 0px 0px" }}>
                            <div className="loading">
                                {isLoadingAdd && 
                                    <>
                                    <div className="arc"></div>
                                    <div className="arc"></div>
                                    <div className="arc"></div>
                                    </>
                                }
                            </div>
                        </div>
                        {!isLoadingAdd &&
                            <>
                            <Button className="AddButton" variant="success" onClick={handleAdd} style={{ margin: "0px 15px 0px 50px" }}>
                                Add
                            </Button>
                        
                            <Button variant="secondary" onClick={closeAdd}>
                                Close
                            </Button>
                            </>
                        }
                        </Modal.Footer>
                    </Modal>
                    
                    <button onClick={handleEdit}>Edit</button>
                    <Modal centered show={showEditWarning} onHide={toggleEditWarning}>
                        <Modal.Header>
                            <Modal.Title><i>Warning!</i></Modal.Title>
                            <button 
                                onClick={toggleEditWarning}
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <span>{contentsEditWarning}</span>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={toggleEditWarning}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal centered show={showEditForm} onHide={toggleEditForm}>
                        <Modal.Header>
                            <Modal.Title><i>Editing</i></Modal.Title>
                            <button 
                                onClick={handleEditFormClose}
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                            <div style={{ margin: "15px" }}>
                                Update information for subject, <u>{catForEdit.title}</u>. 
                                <br />
                                Blank fields will not be updated.
                            </div>
                            <div className="EditLayoutContainer">
                                <div className="EditL">
                                    Current Name
                                    <hr/>
                                    <div className="FormText">{catForEdit.title}</div>
                                </div>
                                <div className="EditR">
                                    Updated Name
                                    <hr/>
                                    <input className="EditInput" onChange={handleEditNameInput} />
                                </div>
                                <div className="EditL">
                                    Current Description
                                    <hr/>
                                    {Object.keys(catForEdit).length>0 &&
                                        <div className="FormText">{catForEdit.description}</div>
                                    }
                                </div>
                                <div className="EditR">
                                    Updated Description
                                    <hr/>
                                    <input className="EditInput" onChange={handleEditDescriptionInput} />
                                </div>
                            </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                        <div className="LoadingContainer1" style={{ margin: "0px 15px 0px 0px" }}>
                                <div className="loading">
                                    {isLoadingEdit && 
                                        <>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        </>
                                    }
                                </div>
                            </div>
                        <Button variant="success" onClick={handleConfirmEdit}>
                            Confirm Changes
                        </Button>
                        <Button variant="secondary" onClick={handleEditFormClose}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    
                    <button onClick={handleRemoveClick}>Remove</button>
                    <Modal centered show={showDeleteWarning} onHide={toggleDeleteWarning}>
                            <Modal.Header>
                                <Modal.Title><i>Warning!</i></Modal.Title>
                                <button 
                                    onClick={toggleDeleteWarning}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                {contentsDeleteWarning}
                                {selectedCategoryPK.length>0 &&
                                categoryData.map((c, i)=>{
                                    if (selectedCategoryPK.includes(c.id)) {
                                       return(<span key={i} style={{ color: "red"}}>{c.title}</span>)
                                    }
                                })
                                }
                                
                            </Modal.Body>
                            <Modal.Footer>
                            <div className="LoadingContainer1" style={{ margin: "0px 150px 0px 0px" }}>
                                <div className="loading">
                                    {isLoadingDelete && 
                                        <>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        </>
                                    }
                                </div>
                            </div>
                            {!isLoadingDelete &&
                                <>
                                <Button className="DeleteButton" variant="danger" onClick={handleDelete} style={{ margin: "0px 15px 0px 50px" }}>
                                    Delete
                                </Button>
                            
                                <Button variant="secondary" onClick={toggleDeleteWarning}>
                                    Close
                                </Button>
                                </>
                            }
                            </Modal.Footer>
                        </Modal>
                </div>
            </div>
            <div>
                {Object.keys(objSubCat).length === 0 &&
                    <span style={{ color: "white" }}>No Categories Defined</span>
                }
                {Object.keys(objSubCat).length > 0 &&
                    Object.keys(objSubCat).map((objKey, idx)=> 
                        <CategoriesPreview 
                            key={idx}
                            objkey={objKey}
                            objval={objSubCat[objKey]}
                            selected={selectedCategoryPK}
                            toSelect={handleCategorySelection}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default Categories;
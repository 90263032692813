import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';


import { authActions } from '../../store/auth';
// import classes from './Header.module.css';
import "./Header.css";

import { FaUserCircle } from 'react-icons/fa';


const Header = () => {

  let history = useHistory();
  
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userToken = useSelector((state) => state.auth.token);
  const userToken2= localStorage.getItem('token')
  // console.log("Header: " + userToken)
  // console.log("Pre-Reload: ")
  // const [localToken, setLocalToken] = useState(userToken)
  // const [usingToken, setUsingToken] = useState(userToken)
  // useEffect(() => {
  //   if (isAuth && userToken.payload) {
  //     let updateUsingToken = usingToken.payload;
  //     setUsingToken(updateUsingToken)
  //   }
  // }, [usingToken])

  const logoutHandler = () => {
    setHover(!hover);
    dispatch(authActions.logout());
    dispatch(authActions.clearToken());
    history.push('/'); // should we keep this here? using <Link to='/' ... /> below
  };

  const [hover, setHover] = useState(false);
  const toggleHover = () => {
    setHover(!hover);
  }

  const user_style = (hover) => {
    if (hover) {
      return(
        { color: "#ffe450", fontSize: "2em", marginLeft: "25px"}
      ) 
    }
    else {
      return(
        { color: "#ffc400", fontSize: "2em", marginLeft: "25px"}
      )
    }
  }

  const authStyle = {
    color: "lime",
    fontSize: "0.75rem"
  }

  const unauthStyle = {
      color: "red",
      fontSize: "0.75rem"
  }
  return(
        // <header className={classes.header}>
        <div className="HeaderContainer">
          <div className="LogoContainer">
            <div className="EdecoTitle" onClick={()=>history.push('/Home')}>
              <h1>EDECO</h1>
            </div>
            <div className="TinyText">
              isAuthenticated: <span>&nbsp;</span>
                <span style={isAuth ? authStyle : unauthStyle }>
                    {isAuth.toString()}
                </span>
            </div>
            <div className="TinyText">
              Token: <span>&nbsp;</span>
              <span style={isAuth ? authStyle : unauthStyle }>
                  {isAuth ? userToken : 'none'}
              </span>
            </div>
          </div>

        {isAuth && 
        <nav>
          <ul>
            <li>
              <Link to="/UserHome">Home</Link>
            </li>
            {/* <li>
              <Link to='/UserProfile'>Profile</Link>
            </li> */}
            <li>
              <Link to='/Subjects'>Subjects</Link>
            </li>
            <li>
              <Link to='/Categories'>Categories</Link>
            </li>
            <li>
              <Link to='/Donate'>Donate</Link>
            </li>
            <li>
              <Link to='/' onClick={logoutHandler}>Logout</Link>
            </li>
            <li>
              <FaUserCircle 
                style={user_style(hover)} 
                // onClick={logoutHandler} 
                onMouseEnter={toggleHover} 
                onMouseLeave={toggleHover} />
            </li>
          </ul>
        </nav>  }
        
        {!isAuth && 
        <nav>
          <ul>
            <li>
              <Link to="/Login">
                <button>Login</button>
              </Link>
            </li>
          </ul>
        </nav>  }
      
      </div>
    )
}

export default Header;
import React, { useState, Component } from 'react';
import ReactDOM from 'react-dom';
import Swipe from 'react-easy-swipe';
import TinderCard from 'react-tinder-card';
import Cards, { Card } from 'react-swipe-card';
import FlashcardButtons from './FlashcardButtons';
import './Flashcard.css';

const Flashcard = () => {
    const [isFront, setIsFront] = useState(true);
    const [cardNumber, setCardNumber] = useState(0);
    const [crctCardCount, setCrctCardCount] = useState(0);
    const [inctCardCount, setInctCardCount] = useState(0);
    const [totalCardCount, setTotalCardCount] = useState(0);

    var element = document.getElementById("Tcard");

    const toggleCard = () => {
        
        // document.querySelector("Tcard").classList.toggle("flip")
        // document.getElementById("Tcard").classList.toggle("flipped")
        // setTimeout(function () {
        setIsFront(!isFront)
        document.getElementById("Card").classList.toggle("flipCard")
        // }, 1500)
    }
    const increaseCrct = () => {
        setCrctCardCount(crctCardCount+1);
        setTotalCardCount(totalCardCount+1);
    }
    const increaseInct = () => {
        setInctCardCount(inctCardCount+1);
        setTotalCardCount(totalCardCount+1);
    }

    const cardPressHandler = (event) => {
        console.log("Hello")
    }

    // const data = ['Michael', 'Jon', 'David', 'Kaori'];

    const data = [
        {
            front: "Front1",
            back: "Back1"
        },
        {
            front: "Front2",
            back: "Back2"
        },
        {
            front: "Front3",
            back: "Back3"
        },
        {
            front: "Front4",
            back: "Back4"
        }
    ];

    return(
        <div className="FlashcardCockpit">
            <div className="ScoreboardContainer">
                <div className="ScoreboardText">
                    Correct
                    <div className="CorrectScore">
                        {crctCardCount}
                    </div>
                </div>
                <div className="ScoreboardText">
                    Incorrect
                    <div className="IncorrectScore">
                        {inctCardCount}
                    </div>
                </div>
                <div className="ScoreboardText">
                    Total
                    <div className="TotalScore">
                        {totalCardCount}
                    </div>
                </div>
            </div>
            <div className="FlashcardContainer">
                {data.map((item, idx) => 
                    <div className="Card" style={{zIndex: (-idx)}} id="Card" key={idx}>
                        <div className="CardFront" id="FCard" 
                            onClick={toggleCard} >
                            <h2>{data[idx].front}</h2>
                        </div>
                        <div className="CardBack">
                            <TinderCard preventSwipe="['up','down']">
                                <div className="TCardBack">
                                <h2>{data[idx].back}</h2>
                                </div>
                            </TinderCard>
                        </div>
                    </div>
                )}
                {/* <div className="Card" id="Card">
                    <div className="CardFront" id="FCard" 
                        onClick={toggleCard} >
                        <h2>Front</h2>
                    </div>
                    <div className="CardBack">
                        <TinderCard preventSwipe="['up','down']">
                            <div className="TCardBack">
                            <h2>Back</h2>
                            </div>
                        </TinderCard>
                    </div>
                </div> */}
                </div>
                <div className="ButtonContainer">
                    <FlashcardButtons 
                        isFront={isFront} 
                        fToggleCard={toggleCard}
                        fIncreaseCrct={increaseCrct}
                        fIncreaseInct={increaseInct}
                    />
                </div>
            {/* </div> */}
        </div>
    )
}

export default Flashcard;
import React from 'react';

import './SubjectScrollbarItem.css';

const SubjectScrollbarItem = (props) => {
    return(
        <>
            <div className="SingleSubjectScrollbar" onClick={props.display}>
                {props.subject}
            </div>
        </>
    )
}

export default SubjectScrollbarItem;
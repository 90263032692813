import { createSlice } from '@reduxjs/toolkit';

const initialToken = localStorage.getItem('token');
const initialIsAuthenticated = !!initialToken;

const initialAuthState = {
    isAuthenticated: initialIsAuthenticated,
    token: initialToken
};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        login(state) {
            state.isAuthenticated = true;        
            localStorage.setItem('token', state.token);
        },
        logout(state) {
            state.isAuthenticated = false;
            state.token = null;
            localStorage.removeItem('token');
        },
        setToken(state, tokenValue) {
            state.token = tokenValue.payload;
        },
        clearToken(state) {
            state.token = null;
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
import React, { useState, useRef } from 'react';
import { Redirect, Route, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authActions } from '../../store/auth';

import './Login.css';
import axios from '../../axios-edeco';

const Login = (props) => {


    const { history } = props;

    const dispatch = useDispatch();
    // const isAuth = useSelector((state) => state.auth.isAuthenticated);
    // const userToken = useSelector((state) => state.auth.token);

    const [newAcct, setNewAcct] = useState(false);
    const [userInput, setUserInput] = useState([]);
    const [passwordInput, setPasswordInput] = useState([]);

    const [newUserInput, setNewUserInput] = useState([]);
    const [validUser, setValidUser] = useState(false);
    const [newPasswordInput, setNewPasswordInput] = useState([]);
    const [validPassword, setValidPassword] = useState(false);
    const [fNameInput, setFNameInput] = useState([]);
    const [validFName, setValidFName] = useState(false);
    const [lNameInput, setLNameInput] = useState([]);
    const [validLName, setValidLName] = useState(false);
    const [emailInput, setEmailInput] = useState([]);
    const [validEmail, setValidEmail] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorCreateAcct, setErrorCreateAcct] = useState(false);

    const toggleCreateSignIn = () => {
        setUserInput("");
        setPasswordInput("");
        setNewUserInput("");
        setNewPasswordInput("");
        setFNameInput("");
        setLNameInput("");
        setEmailInput("");
        setErrorLogin(false);
        setNewAcct(!newAcct)
    };

    const loginHandlerOld = (event) => {
        const { history } = props;
        event.preventDefault();

    };

    const loginHandler = (event) => {
        axios.post('/api/account/obtain_token', {
            username: userInput,
            password: passwordInput
        })
        .then(response => {
            setUserInput("")
            setPasswordInput("")
            let token = response.data.token;
            dispatch(authActions.setToken(token));
            dispatch(authActions.login());
            history.push('/UserHome');
        })
        .catch(error => {
            setErrorLogin(true)
            console.log("Error 400: " + error.message)
        })
    };

    const createAccountHandler = () => {
        console.log("createAccountHandler: FName: " + validFName)
        console.log("createAccountHandler: LName: " + validLName)
        console.log("createAccountHandler: Email: " + validEmail)
        console.log("createAccountHandler: User : " + validUser)
        console.log("createAccountHandler: Passw: " + validPassword)
        if (validFName && validLName && validEmail && validUser && validPassword) {
            console.log("createAccountHandler: All Clear!")
            axios.post('/api/account/create', {
                username: newUserInput,
                first_name: fNameInput,
                last_name: lNameInput,
                email: emailInput,
                timezone: "jpn",
                password: newPasswordInput,
                user_roles: [
                    {
                        title: "Admin",
                        code: "vertices_admin",
                        description: "Admin",
                        features: [
                            {
                                title: "JukuX",
                                description: "Tokorozawa Juku 10",
                                code: "tok_juku_10"
                            }
                        ]
                    }
                ]
            })
            .then(response => {
                setFNameInput("");
                setLNameInput("");
                setEmailInput("");
                setNewUserInput("");
                setNewPasswordInput("");
                console.log(response.data)
                let token = response.data.token;
                dispatch(authActions.setToken(token));
                dispatch(authActions.login());
                history.push('/UserHome');

            })
            .catch(error => {
                setErrorCreateAcct(true)
                console.log(error.message)
                console.log(error.data)
            })
        }
        else {
            setErrorCreateAcct(true)
        }
    };

    const handleUserOnChange = (event) => {
        setUserInput(event.target.value);
    };

    const handlePasswordOnChange = (event) => {
        setPasswordInput(event.target.value)
    };
    const handleNewUserOnChange = (event) => {
        if (event.target.value.length > 0) {
            setValidUser(true)
        }
        else {
            setValidUser(false)
        }
        setNewUserInput(event.target.value);
    };

    const allNumbers = (passString) => {
        let counter = 0;
        for (let i in passString) {
            let element = passString[i]
            if (!isNaN(element)) {
                counter = counter + 1
            }
        }
        if (counter === passString.length) {
            return true
        }
        else {
            return false
        }
    };

    const handleNewPasswordOnChange = (event) => {
        let pass = event.target.value
        if (pass.length>7 && !allNumbers(pass)) {
            setValidPassword(true)
        }
        else {
            setValidPassword(false)
        }
        setNewPasswordInput(pass)
        {/* 8 characters, not common password, not completely numeric */}
    };

    const handleFNameOnChange = (event) => {
        if (event.target.value.length > 0) {
            setValidFName(true);
        }
        else {
            setValidFName(false)
        }
        setFNameInput(event.target.value)
    };

    const handleLNameOnChange = (event) => {
        if (event.target.value.length > 0) {
            setValidLName(true);
        }
        else {
            setValidLName(false)
        }
        setLNameInput(event.target.value)
    };

    const handleEmailOnChange = (event) => {
        if (validateEmail(event.target.value)) {
            setValidEmail(true)
        }
        else {
            setValidEmail(false)
        }
        setEmailInput(event.target.value)
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

    // const getToken = () => {
    //     axios.post('/api/account/obtain_token', {
    //         username: 'msbritton',
    //         password: 'asdfasdf'
    //     })
    //     .then(response => {
    //         let token = response.data.token;
    //         console.log("Token: " + token)
    //     })

    // };

    return(
        <div className="LoginContainer">
            <div className="TextTitle">
                {newAcct ? <h2>Create New Account</h2> : <h2>Login</h2>}
            </div> 
            <div className="AlertContainer">
                {errorLogin && <span>Invalid username and/or password</span>}
                {errorCreateAcct && <span>Invalid fields, please input all fields. Password length 8, not all numbers</span>}
            </div>
                <main className="LoginBox">
                    <section>
                        {newAcct ?
                        <>
                            <form>
                                <div className="Field">
                                    <label htmlFor='fname'>First Name</label>
                                    <input 
                                        type='text' 
                                        id='fname' 
                                        placeholder="e.g. Jon" 
                                        value={fNameInput} 
                                        // style={NameStyle(fNameInput)}
                                        // style={{ border: "2px solid yellow"}}
                                        // className={validFName ? "validFName" : "invalidFName"}
                                        onChange={handleFNameOnChange}
                                    />
                                </div>
                                <div className="Field">
                                    <label htmlFor='lname'>Last Name</label>
                                    <input type='text' id='lname' placeholder="e.g. Doe" value={lNameInput} onChange={handleLNameOnChange}/>
                                </div>
                                <div className="Field">
                                    <label htmlFor='email'>Email</label>
                                    <input type='email' id='email' placeholder="e.g. name@company.com" value={emailInput} onChange={handleEmailOnChange}/>
                                </div>
                                <div className="Field">
                                    <label htmlFor='newusername'>Username</label>
                                    <input type='text' id='newusername' placeholder="e.g. site_user01" value={newUserInput} onChange={handleNewUserOnChange}/>
                                </div>
                                <div className="Field">
                                    <label htmlFor='newpassword'>Password</label>
                                    <input type='password' id='newpassword' placeholder="Please choose a strong password" value={newPasswordInput} onChange={handleNewPasswordOnChange}/>
                                    {/* 8 characters, not common password, not completely numeric */}
                                </div>
                            </form>
                        {/* <Link to="/UserProfile" > */}
                            <div className="SubmitButton">
                                <button onClick={createAccountHandler}>Create Account</button>
                            </div>
                        {/* </Link> */}
                        </>
                        :
                        <>
                            <form>
                                <div className="Field">
                                    <label htmlFor='username'>Username</label>
                                    <input type='username' id='username' value={userInput} onChange={handleUserOnChange}/>
                                </div>
                                <div className="Field">
                                    <label htmlFor='password'>Password</label>
                                    <input type='password' id='password' value={passwordInput} onChange={handlePasswordOnChange} />
                                </div>
                            </form>
                            {/* <Link to="/UserProfile" > */}
                                <div className="SubmitButton">
                                    <button onClick={loginHandler}>Login</button>
                                </div>
                            {/* </Link> */}
                        </>
                        }
                        <div className="CreateSignIn">
                            <button className="CreateSignInButton" onClick={toggleCreateSignIn}>
                                {newAcct ? "Sign In" : "Create new account"}
                            </button>
                        </div>
                        {/* <div>
                            <button onClick={getToken}>Get Token</button>
                        </div> */}
                        {/* </form> */}
                    </section>
                </main>
        </div>
    )
}

export default Login;
import React, { useState } from 'react';

import './SliderOnClick.css';

const SliderOnClick = () => {
    
    const [slide, setSlide] = useState(false);
    const [divSlideOne, setDivSlideOne] = useState(false);
    const [divSlideTwo, setDivSlideTwo] = useState(false); 

    const handleOnClick = () => {
        setSlide(true);
    };

    const handleOffClick = () => {
        setSlide(false);
    };

    const handleDivClickOne = () => {
        setDivSlideOne(!divSlideOne)
    };

    const handleDivClickTwo = () => {
        setDivSlideTwo(!divSlideTwo)
    };

    return(
        <div className="ComponentWrapper">
            <div className="FunctionWrapper">
                <div className="WrapperWrapper">
                    <div className="wrapper">
                        <div className="slide">Web Demo</div>
                    </div>
                    <div className="wrapperTwo" onClick={handleOnClick}>
                        <div className={slide ? "slideThree" : "slideTwo"}>Click Demo</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems:"center", justifyContent:"center"}}>
                        <button style={{width: "100px", margin: "5px 0"}} onClick={handleOnClick}>Appear</button>
                        <button style={{width: "100px", margin: "5px 0"}} onClick={handleOffClick}>Disappear</button>
                    </div>
                </div>
                <div className="Container">
                    <div className="Clicker" onClick={handleDivClickOne}>
                        Click me to open
                    </div>
                    <div className="SliderWrapper">
                        <div className={divSlideOne ? "PostSlider" : "PreSlider"}>
                            I'm sliding!
                        </div>
                    </div>
                </div>
                <div className="Container">
                    <div className="Clicker" onClick={handleDivClickTwo}>
                        Click me to open
                    </div>
                    <div className="SliderWrapper">
                        <div className={divSlideTwo ? "PostSliderTwo" : "PreSliderTwo"}>
                            I'm sliding!
                        </div>
                    </div>
                </div>
            </div>
            <div className="NoteWrapper">
                Notes
                <hr />
                <ul>
                    <li>For the slider to work, we can use #NAME, meaning the element has id="NAME"</li>
                    <ul>
                        <li>CSS : #NAME</li>
                        <li>HTML : div id="NAME"</li>
                    </ul>
                    <li>We can also use .NAME, meaning the div has className="NAME"</li>
                    <ul>
                        <li>CSS : .NAME</li>
                        <li>HTML : div className="NAME"</li>
                    </ul>
                </ul>
            </div>
        </div>
    )
}

export default SliderOnClick;
import React, {  } from 'react';
import ShowButton from './ShowButton';
import FeedbackButton from './FeedbackButton';
import './FlashcardButtons.css';

const FlashcardButtons = (props) => {
    // const something = true;
    if (props.isFront) {
        return(
            <div className="ToggleButton">
                <button className="ShowButton" onClick={()=>props.fToggleCard()}> 
                    Show Reverse
                </button>
                <ShowButton />
            </div>
        )
    } else {
        return(
            <div className="FeedbackButton">
                <button className="IncorrectButton" onClick={()=>props.fIncreaseInct()}>Incorrect</button>
                <button className="ShowButton" onClick={()=>props.fToggleCard()}>Show Reverse</button>
                <button className="CorrectButton" onClick={()=>props.fIncreaseCrct()}>Correct</button>
                <FeedbackButton />
            </div>
        )
    }
}

export default FlashcardButtons;
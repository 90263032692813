import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import '../UserHome/Preview';

import './AllStudy.css';

const AllStudy = (props) => {
    
    let history = useHistory();

    const allCards = props.location.state.cards;
    const studyType= props.location.state.studyType;

    const [categoryObject, setCategoryObject] = useState({});
    const [categoryList, setCategoryList] = useState([]); //index of categoryList should match cardList
    const [cardList, setCardlist] = useState([]); //index of cardList should match categoryList 

    useEffect(() => {
        let catObj = {};
        let catList = [];
        let cdList  = [];
        for (let i in allCards) {
            let iCard = allCards[i];
            let iTitle = iCard.category.title;
            if (catList.includes(iTitle)) {
                console.log(iTitle + " found in catList");
                //don't concat title, but find index and add to cdList
                let titlePosition = catList.indexOf(iTitle);
                let assocCardArray= cdList[titlePosition]; //get the array of cards at the position of the title
                //update cdList by concat-ing the new card onto the associated card array
                // let updateAssocCardArray = assocCardArray.concat(iCard)
                // cdList[titlePosition] = updateAssocCardArray;
                //update catObj value @ catObj[iTitle]
                catObj[iTitle]=catObj[iTitle].concat(iCard)
            }
            else {
                // concat (+1 idx) to lists
                // create key/value in obj
                console.log(iTitle + " not in catList. Adding")
                catList = catList.concat(iTitle);
                cdList = cdList.concat([iCard])
                catObj[iTitle]=[ iCard ]
            }
        }
        console.log("catList: " + catList)
        console.log("cdList: " + cdList[0]) // cdList doesn't need to be updated?
        // console.log("catObj: " + catObj[Object.keys(catObj)[2]][3].markdown)

        setCategoryObject(catObj);
        setCategoryList(catList);
    }, []);

    const getText = (txt, size) => {
        if (txt.length > size) {
            // console.log("getText: " + txt.slice(0, size) + "..." )
            return(txt.slice(0,size) + "...")
        }
        else {
            // console.log("getText: " + txt)
            return(txt)
        }
    }

    const handleCategoryClick = (cards) => {
        history.push({
            pathname: '/Flashcards',
            state: { 
                flashcards: cards
                }
        })
    }
    
    return(
        <div className="AllStudyContainer">
            <div className="AllStudyTitle">
                {studyType}
                <hr />
            </div>
            <div className="StudyMessage">
                You have {allCards.length} cards to study
            </div>


            <>
                {}
            </>
            <div className="AllCategoriesContainer">
                {categoryList.map((catName, cat_idx)=>
                    <div key={cat_idx} className="SingleCategoryContainer">
                        <div className="AllStudyCategoryTitle" onClick={()=>handleCategoryClick(categoryObject[catName])}>
                            {catName}
                        </div>
                        <div className="CategoryFlashcardsContainer">
                            {categoryObject[catName].map((card, card_idx)=>
                                <div key={card_idx} className="FlashcardDataContainer">
                                    <div className="FlashcardPreview">
                                        {getText(card.markdown, 40)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>    
        </div>
    )
}

export default AllStudy;
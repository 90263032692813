import React, { useRef, useState, useEffect } from 'react'

const quickAndDirtyStyle = {
  width: "350px",
  height: "350px",
  background: "#FF9900",
  color: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
}

const MyDraggable = () => {
  //mb new variables
  // const screenWidth = window.innerWidth;
  // const screenHeight= window.innerHeight;

  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({x: 0, y: 0})
  const ref = useRef()

  // Monitor changes to position state and update DOM
  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`
    }
  }, [position])

  // Update the current position if mouse is downn
  const onMouseMove = (event) => {
    if (pressed) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY
      })
    }
  }

  return (
    <div style={{ 
      width: "100vw",
      height: "550px",    
      // border: "2px solid black",
      display: "flex", 
      flexDirection: "column", 
      alignItems: "center", 
      justifyContent: "center"
    }}>
      <h2 style={{ color: "white" }}>Home-Made Draggable</h2>
      <div
        ref={ ref }
        style={ quickAndDirtyStyle }
        onMouseMove={ onMouseMove }
        onMouseDown={ () => setPressed(true) }
        onMouseLeave= { () => setPressed(false) }
        onMouseUp={ () => setPressed(false) }>
        <p>{ pressed ? "Dragging..." : "Press to drag" }</p>
      </div>
    </div>
  )
}

export default MyDraggable

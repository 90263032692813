import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../axios-edeco';

import { authActions } from '../../store/auth';

import './AddSubject.css';

const AddSubject = () => {

    let history = useHistory();
    // MB 2021/08/19 -- history.replace() doesn't allow user to go back (maybe useful for logout)

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);
    
    const [alert, setAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState(true);
    const [alertDescription, setAlertDescription] = useState(true);
    const [title, setTitle] = useState([]);
    const [description, setDescription] = useState([]);
    const [ranking, setRanking] = useState(0);
    const [apiData, setAPIData] = useState({});
    const [loading, setLoading] = useState(false);
    const [categoryFields, setCategoryFields] = useState([1]);
    const [hover, setHover] = useState(false);
    const [categories, setCategories] = useState({});
    const [catDescription, setCatDescription] = useState();

    const handleTitleInput = (event) => {
        if (event.target.value.length < 1) {
            setAlertTitle(true);
        }
        else {
            setAlertTitle(false);
        }
        setTitle(event.target.value);
    };
    
    const handleDescriptionInput = (event) => {
        if (event.target.value.length < 1) {
            setAlertDescription(true);
        }
        else {
            setAlertDescription(false);
        }
        setDescription(event.target.value);
    };
    
    const handleRankingInput = (event) => {
        setRanking(event.target.value);
    };

    const handleCategoryInput = ({ target: { name, value } }) => {
        setCategories((prevState) => ({ ...prevState, [name]: value }))
    };

    const handleCatDescriptionInput = ({ target: { name, value } }) => {
        setCatDescription((prevState) => ({ ...prevState, [name]: value }))
    };

    const submitHandler = () => {
        if (alertTitle || alertDescription) {
            setAlert(true)
        }
        else {
            setAPIData(
                {
                    title: title,
                    description: "",
                    ranking: ranking
                }, 
                setLoading(true),
                axios.post('/api/subject/create', 
                {
                    title: title,
                    description: description,
                    ranking: ranking,
                    feature: {
                        title: "JukuX",
                        description: "Tokorozawa Juku 10",
                        code: "tok_juku_10"
                    }
                },
                { headers: 
                    { 
                        'Authorization': 'Token ' + userToken
                    }
                })
                .catch(error => {
                    console.log(error.message)
                })
                .then(response => {
                    // setLoading(false)
                    if (Object.keys(categories).length > 0) {
                        for (let i = 0; i <= Object.keys(categories).length; i++) {
                            let submitCategoryAPIdata = {
                                title: categories[Object.keys(categories)[i]],
                                description: catDescription[Object.keys(catDescription)[i]],
                                feature: {
                                    title: "JukuX", 
                                    description: "Tokorozawa Juku 10",
                                    code: "tok_juku_10"
                                },
                                subject: {
                                    title: title,
                                    description: description,
                                    feature: {
                                        title: "JukuX",
                                        description: "Tokorozawa Juku 10",
                                        code: "tok_juku_10"
                                    }
                                }
                            }
                            axios.post('/api/category/create', submitCategoryAPIdata,
                            { headers: 
                                { 
                                    'Authorization': 'Token ' + userToken
                                }
                            })
                            .catch(error => {
                                console.log(error.message)
                            })
                            .then(response => {
                                console.log("Category Created: " + categories[Object.keys(categories)[i]])
                            })
                        }
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false)
                        history.push('/Subjects')
                      }, 3000);
                })
            )
        }
    };

    const toggleHover = () => {
        setHover(!hover);
    };

    const requiredRed = {
        color: "firebrick"
    }
    const requiredGreen = {
        color: "limegreen"
    }
    const requiredGray = {
        color: "#474745"
    }

    const addCategoryField = () => {
        let nextValue = categoryFields.length + 1
        let newCategoryFields = categoryFields.concat(nextValue);
        setCategoryFields(newCategoryFields);
        setHover(true);
    };

    return(
        <div className="AddSubjectContainer">
            <div className="AddSubjectTitle">
                Add Subject
                <hr />
            </div>
            <div className="AddSubjectContentsContainer">
                <div className="AlertContainer">
                        <div className="AlertContent" style={alert ? requiredRed : requiredGray}>
                            Please fill in values for 'Title' and 'Description'
                        </div>
                </div> 
                <div className="Button-Field">
                    <div className="SectionName">Title</div>
                    <input type="text" onChange={handleTitleInput}/>
                    <span className="AdditionalText" style={alertTitle ? requiredRed : requiredGreen}>*Required</span>
                </div>
                <div className="Button-Field">
                    <div className="SectionName">Description</div>
                    <input type="text" onChange={handleDescriptionInput}/>
                    <span className="AdditionalText" style={alertDescription ? requiredRed : requiredGreen}>*Required</span>
                </div>
                <div className="Button-Field">
                    <div className="SectionName">Ranking</div>
                    <input type="text" onChange={handleRankingInput} />
                </div>


                {categoryFields.map((element, idx) => {
                    let catID = `cat-${idx}`;
                    let desID = `des-${idx}`;
                    return(
                    <div key={idx}>
                        <div className="Button-Field">
                            <div className="SectionName">
                                Category
                            </div>
                            <input 
                                type="text" 
                                name={catID} 
                                id={catID} 
                                placeholder={"Category #"+idx}
                                onChange={handleCategoryInput} />
                        </div>
                        <div className="Button-FieldDesc">
                            <div className="SectionNameCatDesc">
                                Category Description
                            </div>
                            <input 
                                type="text" 
                                name={desID} 
                                id={desID} 
                                placeholder={"Description #"+idx} 
                                onChange={handleCatDescriptionInput}/>
                            <div className="AddButton">
                                <button 
                                    id={catID}
                                    onClick={addCategoryField}
                                    onMouseEnter={toggleHover}
                                    onMouseLeave={toggleHover}
                                    onFocus={toggleHover} >
                                    +
                                </button>
                            </div>
                        </div>
                    </div>
                    )  
                })
                }

                <div className="Submit">
                    <button onClick={submitHandler}>Submit</button>
                    <div className="loading">
                    {loading && 
                            <>
                            <div className="arc"></div>
                            <div className="arc"></div>
                            <div className="arc"></div>
                            </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSubject;
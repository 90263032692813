import React from 'react';
import './FeedbackButton.css';

const FeedbackButton = () => {
    return(
        <div>

        </div>
    )
    
}

export default FeedbackButton;
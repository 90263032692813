import React, { useState, useEffect }from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../axios-edeco';

import { Button, Modal } from 'react-bootstrap';

// import Preview from '../Study/Preview';
import Preview from '../UserHome/Preview';
import './Subjects.css';

const Subjects = () => {

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);

    const history = useHistory();

    const [SubjectsData, setSubjectsData] = useState([]);
    const [CategoryData, setCategoryData] = useState([]);

    const [alertNoData, setAlertNoData] = useState([]);
    const [alertDisplay, setAlertDisplay] = useState(false);
    const [alertEditCat, setAlertEditCat] = useState(false);

    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [showEditWarning, setShowEditWarning] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [contentsEditWarning, setContentsEditWarning] = useState(""); 
    const [contentsDeleteWarning, setContentsDeleteWarning] = useState("");

    const [selectedCategoriesIdx, setSelectedCategoriesIdx] = useState([]); // the indices of 
    const [selectedCategoriesPK, setSelectedCategoriesPK] = useState([]); 

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);

    const [cardForEdit, setCardForEdit] = useState([]);

    const [categoryFields, setCategoryFields] = useState([1]);

    const [editName, setEditName] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [editCategory, setEditCategory] = useState({});
    const [editCatDescription, setEditCatDescription] = useState({});

    const [removeCategoryPK, setRemoveCategoryPK] = useState([]);


    const handleEditNameInput = (event) => {
        setEditName(event.target.value);
    };

    const handleEditDescriptionInput = (event) => {
        setEditDescription(event.target.value)
    };

    const handleEditCategoryInput = ({ target: { name, value } }) => {
        setEditCategory((prevState) => ({ ...prevState, [name]: value }))
    };

    const handleEditCatDescriptionInput = ({ target: { name, value } }) => {
        setEditCatDescription((prevState) => ({ ...prevState, [name]: value }))
    };

    const addCategoryField = () => {
        let nextValue = categoryFields.length + 1
        let newCategoryFields = categoryFields.concat(nextValue);
        console.log("Test: " + newCategoryFields)
        setCategoryFields(newCategoryFields);
    };

    useEffect(() => {
        axios.get('/api/subject/categories', 
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            }
        })
        .then(response => {
            const data = response.data
            console.log(data.length)
            if (data.length === 0) {
                console.log("No Subjects")
                let alertContents = [
                    <div className="AlertNoSubject">
                        No subject data in database
                    </div>
                ]
                setAlertNoData(alertContents);
                setAlertDisplay(true);
            }
            else {
                setSubjectsData(data);
                let categories = [];
                for (let i in data) {
                    let iCat = data[i].categories;
                    categories = categories.concat(iCat)
                }
                setCategoryData(categories);
            }

        })

    }, [userToken, selectedCategoriesPK, selectedCategoriesIdx])

    const togglePreviewSelected = (idx, pk) => {
        let updateSelectedCategoriesIdx = selectedCategoriesIdx;
        let updateSelectedCategoriesPK = selectedCategoriesPK;
        if (updateSelectedCategoriesIdx.includes(idx)) {
            console.log("Value: " + idx + " removed!")
            let indexOfidx = updateSelectedCategoriesIdx.indexOf(idx);
            if (indexOfidx > -1) {
                updateSelectedCategoriesIdx.splice(indexOfidx, 1);
                updateSelectedCategoriesPK.splice(indexOfidx, 1);
            }
            setSelectedCategoriesIdx(updateSelectedCategoriesIdx);
            setSelectedCategoriesPK(updateSelectedCategoriesPK);
        }
        else {  
            console.log("Value: " + idx + " / " + pk + " added!")
            updateSelectedCategoriesIdx = updateSelectedCategoriesIdx.concat(idx);
            updateSelectedCategoriesPK = updateSelectedCategoriesPK.concat(pk);
            setSelectedCategoriesIdx(updateSelectedCategoriesIdx);
            setSelectedCategoriesPK(updateSelectedCategoriesPK);
        }
    };

    const toggleEditWarning = () => {
        let updateShowEditWarning = !showEditWarning;
        setShowEditWarning(updateShowEditWarning);
    };

    const toggleEditForm = () => {
        let updateShowEditForm = !showEditForm;
        setShowEditForm(updateShowEditForm);
    };

    const handleEdit = () => {
        let updateContentsEditWarning = "";
        if (selectedCategoriesIdx.length === 0) {
            updateContentsEditWarning = "No subject selected. Please select one subject"
            setContentsEditWarning(updateContentsEditWarning);
            toggleEditWarning();
        }
        else if (selectedCategoriesIdx.length > 1) {
            updateContentsEditWarning = "Only one subject can be edited at a time. Please unselect all but one subject"
            setContentsEditWarning(updateContentsEditWarning);
            toggleEditWarning();
        }
        else { //there is exactly one subject selected
            let selectedPK = selectedCategoriesPK[0];
            console.log("Edit card with PK: " + selectedPK)
            console.log("Edit card at locat: " + selectedCategoriesIdx[0])
            for (let i in SubjectsData) {
                let iSubj = SubjectsData[i];
                if (iSubj.id === selectedPK) {
                    console.log("iSubj PK: " + iSubj.id)
                    console.log("Subject: " + iSubj.title)
                    setCardForEdit(iSubj);
                    setShowEditForm(true);
                }
            }
        }
    };

    const toggleDeleteWarning = () => {
        let updateShowDeleteWarning = !showDeleteWarning;
        setShowDeleteWarning(updateShowDeleteWarning);
    };

    const handleDelete = () => {
        setIsLoadingDelete(true);
        for (let i in selectedCategoriesPK) {
            let iPK = selectedCategoriesPK[i];
            axios.delete('/api/subject/'+iPK+'/delete', 
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                }
            })
            .catch(error=>{
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            })
        }
        setSelectedCategoriesPK([]);
        setSelectedCategoriesIdx([]);
        
        setTimeout(() => {
            setShowDeleteWarning(false);
            setIsLoadingDelete(false);
            history.push('/UserHome');
          }, 3000);
    };

    const handleRemoveClick = () => {
        let updateContentsDeleteWarning = "";
        if (selectedCategoriesIdx.length === 0) {
            updateContentsDeleteWarning = "Please select at least one subject to remove"
            setContentsDeleteWarning(updateContentsDeleteWarning);
            toggleDeleteWarning();
        }
        else {
            updateContentsDeleteWarning = "Are you sure you want to delete the following subjects?  Deletions are permanent!";
            setContentsDeleteWarning(updateContentsDeleteWarning);
            toggleDeleteWarning();
        }
    };

    const handleConfirmEdit = () => {
        setIsLoadingEdit(true);
        let updatedEditName = ""
        let editID = cardForEdit.id;
        let numChanges = 0;

        // Subject Name and Description Updates
        if (editName.length === 0) {
            updatedEditName = cardForEdit.title
            console.log("Updated Name: " + updatedEditName )
        }
        else {
            updatedEditName = editName;
            numChanges = numChanges + 1;
            console.log("Updated Name: " + editName)
        }

        let updatedEditDescription = ""
        if (editDescription.length === 0) {
            updatedEditDescription = cardForEdit.description
            console.log("Updated Description: " + updatedEditDescription)
        }
        else {
            updatedEditDescription = editDescription;
            numChanges = numChanges + 1;
            console.log("Updated Description: " + editDescription)
        }

        // Subject Name and Description Updates: API call
        if (numChanges > 0) {
            axios.patch('/api/subject/' + editID + '/update',
                {
                    id: editID,
                    title: updatedEditName,
                    description: updatedEditDescription
                },
                { headers: 
                    { 
                        'Authorization': 'Token ' + userToken
                    }
                })
            .finally(() => {
                // Category Name and Description Update INCLUDING SUBJECT/DESC UPDATE
                if (Object.keys(editCategory).length === 0) {   // editCategory is not populated (untyped)
                    console.log("editCategory hasn't been populated yet")
                }
                else {   // editCategories has key/value pairs
                    let editCategoryList = [];
                    let editCatDescriptionList = [];
                    for (let i in Object.keys(editCategory)) { // i = 0,1,2,3...
                        let iCatValue = Object.values(editCategory)[i];
                        if (iCatValue.length === 0) {  // Category field is empty
                            console.log("Category field empty: Do nothing")
                        }
                        else {   // Category field has value (at least 1 character or more)
                            editCategoryList = editCategoryList.concat(iCatValue); // add value from the field that isn't empty
                            if (Object.keys(editCatDescription).includes('description-'+i)) { //editCatDescription key exists
                                let iCatDescValue = editCatDescription['description-'+i];
                                console.log("Cat: " + iCatValue)
                                console.log("Des: " + iCatDescValue)
                                editCatDescriptionList = editCatDescriptionList.concat(iCatDescValue); // append an empty value at the index where editCategory has a value
                            }
                            else {   // the value in the CatDesc field doesn't exist. Update with blank quotes
                                let iDescValue = "";
                                console.log("CatDescription field has no values. Keeping blank.")
                                editCatDescriptionList = editCatDescriptionList.concat(iDescValue);
                            }
                        }
                    }
                    console.log("CatList: " + editCategoryList);
                    console.log("DesList: " + editCatDescriptionList);
                    console.log("Test: " + cardForEdit.title)
                    // Category Name and Description Update: API Call
                    for (let j in editCategoryList) {
                        let editCatAPIdata = {
                            title: editCategoryList[j],
                            description: editCatDescriptionList[j],
                            feature: {
                                title: "JukuX", 
                                description: "Tokorozawa Juku 10",
                                code: "tok_juku_10"
                            },
                            subject: {
                                title: updatedEditName,
                                description: updatedEditDescription,
                                feature: {
                                    title: "JukuX",
                                    description: "Tokorozawa Juku 10",
                                    code: "tok_juku_10"
                                }
                            }
                        };
                        axios.post('/api/category/create',
                            editCatAPIdata,
                            { headers: 
                                { 
                                    'Authorization': 'Token ' + userToken
                                }
                            });
                    }
                }
                setTimeout(() => {
                    setIsLoadingEdit(false);
                    history.push('/UserHome');
                }, 3000);
            })
        }
        else { // NO CHANGES TO Subject Name / Subject Description
            // Category Name and Description Update WITHOUT SUBJECT/DESC UPDATES
            if (Object.keys(editCategory).length === 0) {   // editCategory is not populated (untyped)
                console.log("editCategory hasn't been populated yet")
            }
            else {   // editCategories has key/value pairs
                let editCategoryList = [];
                let editCatDescriptionList = [];
                for (let i in Object.keys(editCategory)) { // i = 0,1,2,3...
                    let iCatValue = Object.values(editCategory)[i];
                    if (iCatValue.length === 0) {  // Category field is empty
                        console.log("Category field empty: Do nothing")
                    }
                    else {   // Category field has value (at least 1 character or more)
                        editCategoryList = editCategoryList.concat(iCatValue); // add value from the field that isn't empty
                        // if (Object.keys(editCatDescription).length === 0) {
                        //     let iDescValue = "No Description";
                        //     editCatDescriptionList = editCatDescriptionList.concat(iDescValue);
                        // }
                        // else {
                            if (Object.keys(editCatDescription).includes('description-'+i)) { //editCatDescription key exists
                                let iCatDescValue = editCatDescription['description-'+i];
                                // if (iCatDescValue.length === 0) {
                                //     iCatDescValue = "No Description"
                                // }
                                console.log("Cat: " + iCatValue)
                                console.log("Des: " + iCatDescValue)
                                editCatDescriptionList = editCatDescriptionList.concat(iCatDescValue); // append an empty value at the index where editCategory has a value
                            }
                            else {   // the value in the CatDesc field doesn't exist. Update with blank quotes
                                let iDescValue = "No Description";
                                console.log("CatDescription field has no values. Keeping blank.")
                                editCatDescriptionList = editCatDescriptionList.concat(iDescValue);
                            }
                        // }
                    }
                }
                console.log("CatList: " + editCategoryList);
                console.log("DesList: " + editCatDescriptionList);
                console.log("Test: " + cardForEdit.title)
                // Category Name and Description Update: API Call
                for (let j in editCategoryList) {
                    let editCatAPIdata = {
                        title: editCategoryList[j],
                        description: editCatDescriptionList[j],
                        feature: {
                            title: "JukuX", 
                            description: "Tokorozawa Juku 10",
                            code: "tok_juku_10"
                        },
                        subject: {
                            title: updatedEditName,
                            description: updatedEditDescription,
                            feature: {
                                title: "JukuX",
                                description: "Tokorozawa Juku 10",
                                code: "tok_juku_10"
                            }
                        }
                    };
                    axios.post('/api/category/create',
                        editCatAPIdata,
                        { headers: 
                            { 
                                'Authorization': 'Token ' + userToken
                            }
                        });
                }
            }
            setTimeout(() => {
                setIsLoadingEdit(false);
                history.push('/UserHome');
            }, 3000);
        }

        // delete categories
        if (removeCategoryPK.length > 0) {
            for (let k in removeCategoryPK) {
                let kCategoryPK = removeCategoryPK[k];
                axios.delete('/api/category/' + kCategoryPK + '/delete',
                { headers: 
                    { 
                        'Authorization': 'Token ' + userToken
                    }
                })
                // .catch((error)=>{
                //     console.log("Error: " + error.message)
                // })
                // .then((resp)=>{
                //     console.log("Category deleted: " + kCategoryPK)
                //     console.log(resp)
                // })
            }
        }
    };

    const turnOnAlertEditCat = () => {
        setAlertEditCat(true);
    };

    const turnOffAlertEditCat = () => {
        setAlertEditCat(false);
    }

    const handleEditFormClose = () => {
        setCategoryFields([1]);
        setEditName("");
        setEditDescription("");
        setEditCategory({});
        setEditCatDescription({});
        turnOffAlertEditCat();
        setIsLoadingEdit(false);
        setRemoveCategoryPK([]);
        toggleEditForm();
    };

    const handleCheckboxChange = (event, cat) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let updateRemoveCategoryPK = removeCategoryPK;
        if (value) {
            updateRemoveCategoryPK = updateRemoveCategoryPK.concat(cat.id)
            setRemoveCategoryPK(updateRemoveCategoryPK);
        }
        else {
            let categoryPKidx = removeCategoryPK.indexOf(cat.id)
            if (categoryPKidx > -1) {
                updateRemoveCategoryPK.splice(categoryPKidx, 1);
            }
            setRemoveCategoryPK(updateRemoveCategoryPK);
        }
    };

    return(
        <div className="SubjectsContainer">
            <div className="TextTitle">
                <h2>Subjects</h2> 
            </div>
            <div className="SubjectButtons">
                <div className="SubTitle">
                    <span >All Subjects</span>
                </div>
                <div className="ButtonGroup">
                    <Link to="/AddSubject">
                        <button>Add</button>
                    </Link>
                    <button onClick={handleEdit}>Edit</button>
                    <Modal centered show={showEditWarning} onHide={toggleEditWarning}>
                        <Modal.Header>
                            <Modal.Title><i>Warning!</i></Modal.Title>
                            <button 
                                onClick={toggleEditWarning}
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <span>{contentsEditWarning}</span>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={toggleEditWarning}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal centered show={showEditForm} onHide={toggleEditForm}>
                        <Modal.Header>
                            <Modal.Title><i>Editing</i></Modal.Title>
                            <button 
                                onClick={handleEditFormClose}
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                            <div style={{ margin: "15px" }}>
                                Update information for subject, <u>{cardForEdit.title}</u>. 
                                <br />
                                Blank fields will not be updated.
                            </div>
                            <div className="EditLayoutContainer">
                                <div className="EditL">
                                    Current Name
                                    <hr/>
                                    {Object.keys(cardForEdit).length>0 &&
                                        <div className="FormText">{cardForEdit.title}</div>
                                    }
                                </div>
                                <div className="EditR">
                                    Updated Name
                                    <hr/>
                                    <input className="EditInput" onChange={handleEditNameInput} />
                                </div>
                                <div className="EditL">
                                    Current Description
                                    <hr/>
                                    {Object.keys(cardForEdit).length>0 &&
                                        <div className="FormText">{cardForEdit.description}</div>
                                    }
                                </div>
                                <div className="EditR">
                                    Updated Description
                                    <hr/>
                                    <input className="EditInput" onChange={handleEditDescriptionInput} />
                                </div>
                                <div className="EditL">
                                    Current Categories
                                    <hr/>
                                    <ul style={{ margin: "25px 0 0 0" }}>
                                    {CategoryData.map((cat, idx) => {
                                        if (cat.subject.title === cardForEdit.title)
                                            return(
                                            <li key={idx}>
                                                {cat.title}
                                            </li>
                                        )}
                                    )}
                                    </ul>
                                </div>
                                <div className="EditRCat">
                                    Add a category
                                    <hr/>
                                    <div className="WarningAndButton">
                                        <div>
                                            {alertEditCat && 
                                                <span className="EditCatWarning">Category Name Necessary</span>
                                            }
                                        </div>
                                        <button style={{ all: "revert", margin: "0 0 0 0" }} onClick={addCategoryField} >
                                            +
                                        </button>
                                    </div>
                                    {categoryFields.map((element, idx) => {
                                        let catID = `category-${idx}`;
                                        let desID = `description-${idx}`;
                                        return(
                                            <div key={idx}>
                                                <div className="EditCategoryInput">
                                                    <input 
                                                        type="text" 
                                                        name={catID} 
                                                        id={catID} 
                                                        placeholder={"Category Name #" + idx} 
                                                        onChange={handleEditCategoryInput} />
                                                </div>
                                                <div className="EditCatDescriptionInput">
                                                    <input 
                                                        type="text" 
                                                        name={desID} 
                                                        id={desID} 
                                                        placeholder={"Category Description #" + idx} 
                                                        onChange={handleEditCatDescriptionInput}/>
                                                </div>
                                            </div>
                                        )  
                                    })
                                    }
                                </div>
                                <div className="EditL">
                                    
                                </div>
                                <div className="EditR">
                                    Remove Category
                                    <hr/>
                                    {CategoryData.map((cat, idx) => {
                                        if (cat.subject.title === cardForEdit.title) {
                                            return(
                                                <div key={idx} className="RemoveCategoryContainer">
                                                    <div className="RemoveCategoryCheckbox">
                                                        <input 
                                                            type="checkbox" 
                                                            name={cat.title}
                                                            onChange={(e)=>handleCheckboxChange(e,cat)}/>
                                                    </div>
                                                    <div className="RemoveCategoryName">
                                                        {cat.title}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                        <div className="LoadingContainer1" style={{ margin: "0px 15px 0px 0px" }}>
                                <div className="loading">
                                    {isLoadingEdit && 
                                        <>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        </>
                                    }
                                </div>
                            </div>
                        <Button variant="success" onClick={handleConfirmEdit}>
                            Confirm Changes
                        </Button>
                        <Button variant="secondary" onClick={handleEditFormClose}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <button onClick={handleRemoveClick}>Remove</button>
                    <Modal centered show={showDeleteWarning} onHide={toggleDeleteWarning}>
                            <Modal.Header>
                                <Modal.Title><i>Warning!</i></Modal.Title>
                                <button 
                                    onClick={toggleDeleteWarning}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                {contentsDeleteWarning}
                                {selectedCategoriesIdx.length>0 &&
                                SubjectsData.map((s, i)=>{
                                    if (selectedCategoriesPK.includes(s.id)) {
                                       return(<span key={i} style={{ color: "red"}}>{s.title}</span>)
                                    }
                                })
                                }
                                
                            </Modal.Body>
                            <Modal.Footer>
                            <div className="LoadingContainer1" style={{ margin: "0px 150px 0px 0px" }}>
                                <div className="loading">
                                    {isLoadingDelete && 
                                        <>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        <div className="arc"></div>
                                        </>
                                    }
                                </div>
                            </div>
                            {selectedCategoriesIdx.length>0 &&
                                <Button className="DeleteButton" variant="danger" onClick={handleDelete} style={{ margin: "0px 15px 0px 50px" }}>
                                    Delete
                                </Button>
                            }
                            <Button variant="secondary" onClick={toggleDeleteWarning}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>

                </div>
            </div>
            <hr />
            {alertDisplay && alertNoData}
            <>
            {SubjectsData.map((subj, idx) =>
                    <div className="SubjectDisplayContainer" key={idx} >
                        <Preview 
                            key={idx}
                            superGroup={subj.title} 
                            subGroup={SubjectsData} 
                            subType={"category"}
                            identifier={idx}
                            fToggleSelected={togglePreviewSelected}
                            selectedList={selectedCategoriesIdx}
                        />
                    </div>
            )}
            </>
        </div>
    )
}

export default Subjects;
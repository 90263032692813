import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import Swipe from 'react-easy-swipe';

class MySwipe extends Component {
    onSwipeStart(event) {
      console.log('Start swiping...', event);
    }
   
    onSwipeMove(position, event) {
      console.log(`Moved ${position.x} pixels horizontally`, event);
      console.log(`Moved ${position.y} pixels vertically`, event);
    }
   
    onSwipeEnd(event) {
      console.log('End swiping...', event);
    }
   
    render() {
        const boxStyle = {
            width: '275px',
            height: '33vh',
            border: '1px solid black',
            background: 'lightgray',
            padding: '20px',
            margin: '20px',
            fontSize: '1em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        };
        const controlContainer = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        };
      return (
        <div className="SwipeCockpit">
            <h2 style={{ color: "white" }}>React-Easy-Swipe</h2>
            <Swipe
                onSwipeStart={this.onSwipeStart}
                onSwipeMove={this.onSwipeMove}
                onSwipeEnd={this.onSwipeEnd}
                allowMouseEvents>
                    <div style={controlContainer}>
                        <div className="InformationContainer">
                            <h4  style={{ color: "white" }}>Information</h4>
                            <div style={{ color: "white" }}>Swipe Start: {this.onSwipeStart}</div>
                            <div style={{ color: "white" }}>Swipe Move : {this.onSwipeMove}</div>
                            <div style={{ color: "white" }}>Swipe End  : {this.onSwipeEnd}</div>
                        </div>
                        <div style={boxStyle}>Swipe</div>
                    </div>
            </Swipe>
        </div>
      );
    }
  }
   
//   ReactDOM.render(<MySwipe/>, document.getElementById('root'));

export default MySwipe;
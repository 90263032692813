import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Category from '../Category/Category';

import './Preview.css';


//MB - Calling this "Preview" because I intend to use it for both the Category Preview (Categories and Flashcards)
//     and the Subject Preview (Subjects and Categories)
const Preview = (props) => {

    const [subgroup, setSubgroup] = useState([]);
    const [hover, setHover] = useState(false);
    const [clickedInDiv, setClickedInDiv] = useState(0);

    useEffect(() => {
        let subgroupMembers = [];
        for (let i in props.subGroup) {
            if(props.subType === "flashcard"){
                if (props.superGroup === props.subGroup[i].title) {
                    subgroupMembers = subgroupMembers.concat(props.subGroup[i].flash_cards)
                }
            }
            else { //do stuff for "category"
                if (props.superGroup === props.subGroup[i].title) {
                    subgroupMembers = subgroupMembers.concat(props.subGroup[i].categories)
                }
            }
        }
        //console.log("subGroupMembers: " + subgroupMembers)
        setSubgroup(subgroupMembers);
    }, [props.subGroup, props.superGroup, props.subType]); //double-check correctness of these variables

    const getText = (txt, size) => {
        if (txt.length > size) {
            // console.log("getText: " + txt.slice(0, size) + "..." )
            return(txt.slice(0,size)+"...")
        }
        else {
            // console.log("getText: " + txt)
            return(txt)
        }
    }

    const handleSuperGroupSelect = (subType) => {
        if(subType==="flashcard") {

        }
        else {
            console.log("PK: " + subType)
        }
        
    }

    const handleMouseOver = () => {
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
        setClickedInDiv(0);
    }

    const handleClick = (props) => {
        // setHover(false);
        let updateClickedInDiv = clickedInDiv;
        updateClickedInDiv = updateClickedInDiv + 1;
        setClickedInDiv(updateClickedInDiv);
        props.fToggleSelected(props.identifier, props.subGroup[props.identifier].id);
    }

    const superGroupFlashcardStyle = {
        maxHeight: "9rem"
    };

    const superGroupCategoryStyle = {
        maxHeight: "11rem"
    };

    const hoverStyle = {
        borderColor: "white"
    }

    const selectedStyle = {
        border: "3.5px solid yellow"
    }

    const notSelectedStyle = {
        borderColor: "black"
    }

    return(
        <div className="SingleCategoryDisplayContainer">
            <div 
                className={props.subType==="category" ? "CategorySub" : "Category" } 
                // onClick={props.subType==="category" ? ()=> {
                //     props.fToggleSelected(props.identifier, props.subGroup[props.identifier].id);
                //     setHover(false)} : null } 
                onClick={props.subType==="category" ? ()=>handleClick(props) : null } 
                onMouseOver={props.subType==="category" ? handleMouseOver : null } 
                onMouseLeave={props.subType==="category" ? handleMouseLeave : null }
                style={props.subType==="flashcard" ? null 
                    : props.selectedList.includes(props.identifier) ? selectedStyle    
                    : hover&&clickedInDiv>0 ? notSelectedStyle  
                    : hover ? selectedStyle
                    : notSelectedStyle }
            >
                {props.superGroup}
            </div>
            <div 
                className="CategoryFlashcardSetContainer" 
                style={props.subType==="flashcard" ? superGroupFlashcardStyle : superGroupCategoryStyle}
            >
                {subgroup.map((member, idx) => 
                    <div className="CategoryFlashcardContainer" key={idx}>
                        <div className="Preview" key={idx}>
                            {/* Need to take a specific # of characters */}
                            {(props.subType === "flashcard") ? getText(member.markdown, 40)
                                : props.subType === "category" ? (
                                    <Link 
                                        className="PreviewLink" 
                                        to={{
                                            pathname: "/Category",
                                            state: {
                                                category: member.title
                                            }
                                        }} 
                                        key={idx}>
                                            {getText(member.title, 40)} 
                                    </Link>)
                                : null
                            }
                            
                        </div>
                        {(props.subType === "category") &&
                            <div className="Description">
                                {getText(member.description, 20)}
                            </div>
                        }
                </div>
                )}
            </div>
        </div>
    )
}

export default Preview;
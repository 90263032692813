import React from 'react';
import Cards, { Card } from 'react-swipe-card';
import './MySwipeCard.css';


const data = ['Alexandre', 'Thomas', 'Lucien']

const action = (action) => {
    console.log('action: ', action);
}

const MySwipeCard = () => {
  return (
	  <Cards onEnd={action('end')} className='master-root'>
        {data.map((item,idx) => 
          <Card key={idx}
            className="card"
            onSwipeLeft={action('swipe left')} 
            onSwipeRight={action('swipe right')}>
            <h2>{item}</h2>
          </Card>
        )}
      </Cards>
  )
}

export default MySwipeCard;
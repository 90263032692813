import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Category from '../Category/Category';

import './Preview.css';


//MB - Calling this "Preview" because I intend to use it for both the Category Preview (Categories and Flashcards)
//     and the Subject Preview (Subjects and Categories)
const Preview = (props) => {

    const [subgroup, setSubgroup] = useState([]);
    const [cardSlide, setCardSlide] = useState(false);

    useEffect(() => {
        let subgroupMembers = [];
        for (let i in props.subGroup) {
            if(props.subType === "flashcard"){
                if (props.superGroup === props.subGroup[i].title) {
                    subgroupMembers = subgroupMembers.concat(props.subGroup[i].flash_cards)
                }
            }
            else { //do stuff for "category"
                if (props.superGroup === props.subGroup[i].title) {
                    subgroupMembers = subgroupMembers.concat(props.subGroup[i].categories)
                }
            }
        }
        console.log("subGroupMembers: " + subgroupMembers) 
        setSubgroup(subgroupMembers)
    }, [props.subGroup, props.superGroup, props.subType]); //double-check correctness of these variables

    const getText = (txt, size) => {
        if (txt.length > size) {
            // console.log("getText: " + txt.slice(0, size) + "..." )
            return(txt.slice(0,size)+"...")
        }
        else {
            // console.log("getText: " + txt)
            return(txt)
        }
    }

    const handleCategoryClick = () => {
        setCardSlide(!cardSlide);
    }

    return(
        <div className="SingleCategoryDisplayContainer">
            <div className="Category" onClick={handleCategoryClick}>
                {props.superGroup}
            </div>
            <div className="CategoryFlashcardSetContainer">
                {/* {console.log("SubGroup: " + subgroup.length)} */}
                {subgroup.map((member, idx) => 
                    <div className="CategoryFlashcardContainer" key={idx}>
                        <div className="Preview" key={idx}>
                            {/* Need to take a specific # of characters */}
                            {(props.subType === "flashcard") ? getText(member.markdown, 40)
                                : props.subType === "category" ? (
                                    <Link 
                                        className="PreviewLink" 
                                        to={{
                                            pathname: "/Category",
                                            state: {
                                                category: member.title
                                            }
                                        }} 
                                        key={idx}>
                                            {getText(member.title, 40)} 
                                    </Link>)
                                : null
                            }
                            
                        </div>
                        {(props.subType === "category") &&
                            <div className="Description">
                                {getText(member.description, 20)}
                            </div>
                        }
                </div>
                )}
            </div>
        </div>
    )
}

export default Preview;
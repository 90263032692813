import React, { useState } from 'react';

import OneCategory from './OneCategory';

import './CategoriesPreview.css';

const CategoriesPreview = (props) => {
    
    const objkey = props.objkey;
    const objval = props.objval;
    const selectedPKList = props.selected;
    const handleCatSelection = props.toSelect;

    const [selectedPK, setSelectedPK] = useState([]);
    const [hover, setHover] = useState(false);
    const [clickedInDiv, setClickedInDiv] = useState(0);
    const [hoverArray, setHoverArray] = useState([]);
    
    // const handleSelect = (cat) => {
    //     let catPK = cat.id;
    //     let updateSelectedPK = selectedPK;
    //     console.log("PK List 1: " + updateSelectedPK)
    //     if (selectedPK.includes(catPK)) {
    //         let indexOfPK = updateSelectedPK.indexOf(catPK);
    //         if (indexOfPK > -1) {
    //             updateSelectedPK.splice(indexOfPK, 1);
    //             console.log("PK List 2: " + updateSelectedPK)
    //             setSelectedPK(updateSelectedPK);
    //         }
    //     }
    //     else {
    //         updateSelectedPK = updateSelectedPK.concat(catPK);
    //         console.log("PK List 2: " + updateSelectedPK)
    //         setSelectedPK(updateSelectedPK);
    //     }
    // }

    const selectedStyle = {
        border: "3.5px solid yellow"
    }

    const notSelectedStyle = {
        borderColor: "black"
    }


    const handleMouseOver = (idx) => {
        // setHover(true);
        
        let updateHoverArray = hoverArray;
        updateHoverArray[idx] = true;
        // console.log("HA: " + updateHoverArray)
        setHoverArray(updateHoverArray);
    }

    const handleMouseLeave = (idx) => {
        // setHover(false);
        let updateHoverArray = hoverArray;
        updateHoverArray[idx] = false;
        // console.log("HA: " + updateHoverArray)
        setHoverArray(updateHoverArray);
        setClickedInDiv(0);
    }

    return(
        <div className="CategoriesPreviewContainer">
            <div className="SubjectHeaderSlim">
                {objkey}
            </div>
            <div className="AllCategoriesInSubject">
                {objval.map((cat, idx)=> {
                    let updateHoverArray = hoverArray;
                    updateHoverArray[idx] = false;
                    return(
                        <OneCategory 
                            key={idx}
                            // onClick={()=>props.toSelect(cat.id)}
                            catInfo={cat}
                            PKs={selectedPKList}
                            toSelect={props.toSelect}
                        />
                    // <div className="IndividualCategoriesCard" 
                    //      key={idx} 
                    //      onClick={()=>props.toSelect(cat.id)}
                    //      onMouseOver={()=>handleMouseOver(idx)} 
                    //      onMouseLeave={()=>handleMouseLeave(idx)}
                    //      style={selectedPKList.includes(cat.id) ? selectedStyle : notSelectedStyle} 
                    // >
                    //     {cat.title}
                    // </div>
                    )}
                )}
            </div>
            <hr />
        </div>
    )
}

export default CategoriesPreview;
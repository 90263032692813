// import React, { useState } from 'react';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../axios-edeco';
// import Library from '../Flashcard/Library';

import { authActions } from '../../store/auth';
import './Home.css';

const Home = () => {

    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);
    const history = useHistory();

    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const authStyle = {
        color: "lime"
    }

    const unauthStyle = {
        color: "red"
    }

    const makeSubjects = () => {
        let mySubjects = ["History","TV Culture","National Culinary Certification","Beauty School"];
        let myDescriptions=["ES-JHS History","Notable Personalities","National Chef Curriculum","Essentials in Beauty"];
        for (let i in mySubjects) {
            let iMySubject = mySubjects[i];
            let iMyDescription = myDescriptions[i]
            axios.post('/api/subject/create', 
            {
                title: iMySubject,
                description: iMyDescription,
                ranking: 0,
                feature: {
                    title: "JukuX",
                    description: "Tokorozawa Juku 10",
                    code: "tok_juku_10"
                }
            },
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                }
            })
        }
    }
    
    const makeCategories = () => {
        let mySubjects = ["History","TV Culture","National Culinary Certification","Beauty School"];
        let mySDescriptions=["ES-JHS History","Notable Personalities","National Chef Curriculum","Essentials in Beauty"];
        let myCategories=[["Biblical Times","Middle-Ages","Renaissance","World War II"],["Gameshows","Cartoons","Dramas"],["Breakfast","Brunch","Lunch","Dinner","Dessert"],["Makeup","Hair Styling","Dyeing"]];
        let myDescriptions=[["What went down with Jesus","After the Romans","More art and thinking","Everyone hates each other"],["Games on TV","Animation","Modern Drama"],["1st meal of the day","Between Breakfast and Lunch","2nd Meal of the Day","3rd Meal of the Day","After dinner"],["Paint womens' faces","Making hair look cool","Chameleon that shit"]];
        for (let i in mySubjects) {
            let iMySubject = mySubjects[i];
            let iMySDescription = mySDescriptions[i];
            let iMyCategories = myCategories[i];
            let iMyDescriptions = myDescriptions[i];
            for (let j in iMyCategories) {
                let jMyCategory = iMyCategories[j];
                let jMyDescription = iMyDescriptions[j];
                axios.post('/api/category/create', 
                {
                    title: jMyCategory,
                    description: jMyDescription,
                    feature: {
                        title: "JukuX", 
                        description: "Tokorozawa Juku 10",
                        code: "tok_juku_10"
                    },
                    subject: {
                        title: iMySubject,
                        description: iMySDescription,
                        feature: {
                            title: "JukuX",
                            description: "Tokorozawa Juku 10",
                            code: "tok_juku_10"
                        }
                    }
                }
                ,
                { headers: 
                    { 
                        'Authorization': 'Token ' + userToken
                    }
                })
            }
        }
    }
    
    const makeFlashcards = () => {
        let mySubjects = ["History","TV Culture","National Culinary Certification","Beauty School"];
        let mySDescriptions=["ES-JHS History","Notable Personalities","National Chef Curriculum","Essentials in Beauty"];
        let myCategories=[["Biblical Times","Middle-Ages","Renaissance","World War II"],["Gameshows","Cartoons","Dramas"],["Breakfast","Brunch","Lunch","Dinner","Dessert"],["Makeup","Hair Styling","Dyeing"]];
        let myDescriptions=[["What went down with Jesus","After the Romans","More art and thinking","Everyone hates each other"],["Games on TV","Animation","Modern Drama"],["1st meal of the day","Between Breakfast and Lunch","2nd Meal of the Day","3rd Meal of the Day","After dinner"],["Paint womens' faces","Making hair look cool","Chameleon that shit"]];
        let myCards = [[["Jesus\r\n\Son of God","David\r\nSlaughtered Big Man","Tower of Babel\r\nEarly Advances in Architecture","Mary\r\nVirgin?","Genesis\r\nBigBang","Revelations\r\nHumans Screwed?"],
                        ["MiddleAges1\r\nAD Calendar Invented","MiddleAges2\r\nJustinian Plague","MiddleAges3\r\nSt Columbus founds Iona, Christianty Strengthens","MiddleAges4\r\nTang Dynasty Begins","MiddleAges5\r\nIslamic Caliphate Conquers Persia","MiddleAges6\r\nCharlemagne Crowned Emperor"],
                        ["Renaissance1\r\nGutenberg Bible Printed en masse","Renaissance2\r\nMichelangelo finishes David","Renaissance3\r\nBlack Death","Renaissance4\r\nRise of the Medicis","Renaissance5\r\nAge of Exploration: Columbus, Vasco DeGama, Bartolomeu Dias","Renaissance6\r\nPeace of Augsburg: Protestant/Catholic tensions down"],
                        ["Start of the war\r\n1939 Germany invades Poland","Invasion of Normandy\r\nTurning point for Allies","Axis Leaders\r\nMussolini, Hitler, Hirohito","Allied Leaders\r\nWinston Churchill, Franklin D. Roosevelt, Joseph Stalin, Charles de Gaulle","1941 December 7th\r\nJapanese attack at Pearl Harbor","1945 September 2nd\r\nEnd of the war"]],  
                       [["Alex Trebek\r\nJeopary","Pat Sajak\r\nWheel of Fortune","Bob Barker\r\nPrice is Right","Steve Harvey\r\nFamily Feud","Marc Summers\r\nDouble Dare","Monty Hall\r\nLet's Make A Deal"],
                        ["Scrooge McDuck\r\nDucktales","Tommy Pickles\r\nRugrats","Krumm\r\nAah!Real Monsters","Johnny Bravo\r\nJohnny Bravo","Jack\r\nSamurai Jack","Aang\r\nAvatar"],
                        ["Blake Carrington\r\nDynasty","Captain Reynolds\r\nFirefly","Walter White\r\nBreaking Bad","Al Swearengen\r\nDeadwood","John Locke\r\nLost","Bernard Lowe\r\nWestworld"]],  
                       [["Huevos Rancheros\r\nRecipe...","Golden Grahams\r\nRecipe...","Cinnamon Toast Crunch\r\nRecipe...","Chorizo Omlet\r\nRecipe...","Chilaquiles\r\nRecipe...","English Breakfast\r\nRecipe..."],
                        ["Eggs Benedict\r\nRecipe...","Macadamia Nut Frosted Pancakes\r\nRecipe...","Power Lunch\r\nRecipe...","Mimosas\r\nRecipe...","Avocado Toast\r\nRecipe...","Shakshouka\r\nRecipe..."],
                        ["Roast Beef Sandwich\r\nRecipe...","Ftira\r\nRecipe...","Chicken Wrap\r\nRecipe...","Greek Salad\r\nRecipe...","Tuna Salad\r\nRecipe...","Kyushoku\r\nRecipe..."],
                        ["Chicken Parmesan\r\nRecipe...","Rabbit Stew\r\nRecipe...","Chile Verde\r\nRecipe...","Grey Meat\r\nRecipe...","Beef Stroganoff\r\nRecipe...","Rosemary Masala Chicken\r\nRecipe..."],
                        ["Pistachio Gelato\r\nRecipe...","Devil's Food Cake\r\nRecipe...","Strawberry Angel Food Cake\r\nRecipe...","Red Wine and Cheese\r\nRecipe...","Kakigoori\r\nRecipe...","Anmitsu\r\nRecipe..."]],  
                       [["Makeup 1\r\nFoundation","Makeup 2\r\nShading I: Eyes","Makeup 3\r\nShading II: Cheeks","Makeup 4\r\nLips","Makeup 5\r\nNatural Colors","Makeup 6\r\nClown Makeup"],
                        ["Men1:\r\nCrew Cut","Men2:\r\nUndercut","Men3\r\nSide-part","Women1:\r\nLong and Sleek","Women2:\r\nEasy Waves","Women3:\r\nCurtain Bangs"],
                        ["Dye1:\r\nChocolate and Caramel Balayage","Dye2:\r\nBrunette with Auburn Highlights","Dye3:\r\nChocolate Latte Hair","Dye4:\r\nSun-Kissed Hair","Dye5:\r\nCaramel Mocha","Dye6:\r\nBald"]]];
        for (let i in mySubjects) {
            let iMySubject = mySubjects[i];
            let iMySDescription = mySDescriptions[i];
            let iMyCards = myCards[i];
            let iMyCategories = myCategories[i];
            let iMyDescriptions = myDescriptions[i];
            for (let j in iMyCategories) {
                let jMyCategory = iMyCategories[j];
                let jMyDescription = iMyDescriptions[j];
                let jMyCards = iMyCards[j];
                for (let k in jMyCards) {
                    let kMyCard = jMyCards[k];
                    axios.post('/api/flashcard/create', 
                    {
                        markdown: kMyCard,
                        category: {
                            title: jMyCategory,
                            description: jMyDescription,
                            feature: {
                                title: "JukuX",
                                description: "Tokorozawa Juku 10",
                                code: "tok_juku_10"
                            },
                            subject: {
                                title: iMySubject,
                                description: iMySDescription,
                                feature: {
                                    title: "JukuX",
                                    description: "Tokorozawa Juku 10",
                                    code: "tok_juku_10"
                                }
                            }
                        },
                        feature: {
                            title: "JukuX",
                            description: "Tokorozawa Juku 10",
                            code: "tok_juku_10"
                        }
                    }
                    ,
                    { headers: 
                        { 
                            'Authorization': 'Token ' + userToken
                        }
                    })
                    .catch(error=> {
                        console.log(error.message)
                    })
                }
            }
        }
    }


    const makeSubjectsTwo = () => {
        let mySubjects = ["Neuroscience","Biology"];
        let myDescriptions=["Intro to Neuroscience","Animals and Shit"];
        for (let i in mySubjects) {
            let iMySubject = mySubjects[i];
            let iMyDescription = myDescriptions[i]
            axios.post('/api/subject/create', 
            {
                title: iMySubject,
                description: iMyDescription,
                ranking: 0,
                feature: {
                    title: "JukuX",
                    description: "Tokorozawa Juku 10",
                    code: "tok_juku_10"
                }
            },
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                }
            })
        }
    }
    
    const makeCategoriesTwo = () => {
        let mySubjects = ["Neuroscience","Biology"];
        let mySDescriptions=["Intro to Neuroscience","Animals and Shit"];
        let myCategories=[["Neuromolecules","Neuropathology"],["Vertibrates","Marine Biology"]];
        let myDescriptions=[["Neuro 510","Gonna be a neurosurgeon"],["Animals with backbones","Hell on Earth"]];
        for (let i in mySubjects) {
            let iMySubject = mySubjects[i];
            let iMySDescription = mySDescriptions[i];
            let iMyCategories = myCategories[i];
            let iMyDescriptions = myDescriptions[i];
            for (let j in iMyCategories) {
                let jMyCategory = iMyCategories[j];
                let jMyDescription = iMyDescriptions[j];
                axios.post('/api/category/create', 
                {
                    title: jMyCategory,
                    description: jMyDescription,
                    feature: {
                        title: "JukuX", 
                        description: "Tokorozawa Juku 10",
                        code: "tok_juku_10"
                    },
                    subject: {
                        title: iMySubject,
                        description: iMySDescription,
                        feature: {
                            title: "JukuX",
                            description: "Tokorozawa Juku 10",
                            code: "tok_juku_10"
                        }
                    }
                }
                ,
                { headers: 
                    { 
                        'Authorization': 'Token ' + userToken
                    }
                })
            }
        }
    }

    const makeFlashcardsTwo = () => {
        let mySubjects = ["Neuroscience","Biology"];
        let mySDescriptions=["Intro to Neuroscience","Animals and Shit"];
        let myCategories=[["Neuromolecules","Neuropathology"],["Vertibrates","Marine Biology"]];
        let myDescriptions=[["Neuro 510","Gonna be a neurosurgeon"],["Animals with backbones","Hell on Earth"]];
        let myCards = [[["Dopamine\r\nC8H11NO2","Acetylcholine\r\nC7H16NO2","Epinephrine\r\nC9H13NO3","Norepinephrine\r\nC8H11NO3","Histamine\r\nC5H9N3","Serotonin\r\nC10H12N2O ", "GABA\r\nC4H9NO2"],
                        ["Parkinson's\r\n","Alzheimers\r\n","Krabbe Disease\r\n","Huntington's Disease\r\n","Amyotrophic lateral sclerosis/Lou Gherig's Disease\r\n","Ceroid-lipofuscinosis\r\n"]],  
                       [["Whale\r\nBig","Human\r\nMedium","Cat\r\nSmall","Salamander\r\nSmall","Giraffe\r\nBig","Gorilla\r\nMedium"],
                        ["Sunfish\r\nマンボー","Whale\r\nクジラ","Whaleshark\r\nジンベエザメ","Anglerfish\r\nアンコウ","Jellyfish\r\nクラゲ","Seahorse\r\nタツノオトシゴ"]]];
        for (let i in mySubjects) {
            let iMySubject = mySubjects[i];
            let iMySDescription = mySDescriptions[i];
            let iMyCards = myCards[i];
            let iMyCategories = myCategories[i];
            let iMyDescriptions = myDescriptions[i];
            for (let j in iMyCategories) {
                let jMyCategory = iMyCategories[j];
                let jMyDescription = iMyDescriptions[j];
                let jMyCards = iMyCards[j];
                for (let k in jMyCards) {
                    let kMyCard = jMyCards[k];
                    axios.post('/api/flashcard/create', 
                    {
                        markdown: kMyCard,
                        category: {
                            title: jMyCategory,
                            description: jMyDescription,
                            feature: {
                                title: "JukuX",
                                description: "Tokorozawa Juku 10",
                                code: "tok_juku_10"
                            },
                            subject: {
                                title: iMySubject,
                                description: iMySDescription,
                                feature: {
                                    title: "JukuX",
                                    description: "Tokorozawa Juku 10",
                                    code: "tok_juku_10"
                                }
                            }
                        },
                        feature: {
                            title: "JukuX",
                            description: "Tokorozawa Juku 10",
                            code: "tok_juku_10"
                        }
                    }
                    ,
                    { headers: 
                        { 
                            'Authorization': 'Token ' + userToken
                        }
                    })
                    .catch(error=> {
                        console.log(error.message)
                    })
                }
            }
        }
    }

    const makeAll = () => {
        setLoading1(true);
        makeSubjects();
        setTimeout(() => {
            console.log("Created subjects!")
            setLoading2(true);
            makeCategories();
            setTimeout(() => {
                console.log("Created categories!")
                setLoading3(true);
                makeFlashcards();
                setTimeout(() => {
                    console.log("Created flashcards!")
                    setLoading1(false);
                    setLoading2(false);
                    setLoading3(false);
                    if (isAuth) {
                        history.push('/Subjects')
                    }
                    else {
                        history.push('/Login')
                    }
                }, 2500);
            }, 2500);
          }, 2500);
    } 

    const makeAllTwo = () => {
        setLoading1(true);
        makeSubjectsTwo();
        setTimeout(() => {
            console.log("Created subjects!")
            setLoading2(true);
            makeCategoriesTwo();
            setTimeout(() => {
                console.log("Created categories!")
                setLoading3(true)
                makeFlashcardsTwo();
                setTimeout(() => {
                    console.log("Created flashcards!")
                    setLoading1(false);
                    setLoading2(false);
                    setLoading3(false);
                    if (isAuth) {
                        history.push('/Subjects')
                    }
                    else {
                        history.push('/Login')
                    }
                }, 2500);
            }, 2500);
          }, 2500);
    } 

    return(
        <div className="HomeContainer">
            <div className="TextTitle">
                <h2>Home</h2> 
            </div>
            <div>EDECO HOMEPAGE</div>
            <hr />
            <div>
                isAuthenticated: <span>&nbsp;</span>
                <span style={isAuth ? authStyle : unauthStyle }>
                    {isAuth.toString()}
                </span>
            </div>
            <hr />
            <div>Library</div>
            <div className="LibraryButton">
                <Link to="/Library">
                    <button>
                        Library
                    </button>
                </Link>
                <div>API Calls</div>
                <div className="CreateAll"> 
                    {/* <button onClick={makeSubjects}>
                        Make Subjects
                    </button>
                    <button onClick={makeCategories}>
                        Make Categories
                    </button>
                    <button onClick={makeFlashcards}>
                        Make Flashcards
                    </button> */}
                    
                    <div className="CreateSmallButton">
                        <button style={{ width: "200px" }} onClick={makeAllTwo}>
                            Create Small Set
                        </button>
                    </div>
                    
                    <div className="LoadingContainerHome">
                        <div className="loading">
                            {loading1 && 
                                <>
                                <div className="arc"></div>
                                <div className="arc"></div>
                                <div className="arc"></div>
                                </>
                            }
                        </div>
                        <div className="loading">
                            {loading2 && 
                                <>
                                <div className="arc"></div>
                                <div className="arc"></div>
                                <div className="arc"></div>
                                </>
                            }  
                        </div>
                        <div className="loading">
                            {loading3 && 
                                <>
                                <div className="arc"></div>
                                <div className="arc"></div>
                                <div className="arc"></div>
                                </>
                            }
                        </div>
                    </div>

                    <div className="CreateBigButton">
                        <button style={{ width: "200px" }} onClick={makeAll}>
                            Create Big Set
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Home;
import React, { useState } from 'react';
import TinderCard from 'react-tinder-card';
import './MyTinder.css';


//array of objects: an image and a name


const MyTinder = () => {

    const [isFront, setIsFront] = useState(true);

    const toggleCard = () => {
        document.getElementById("Card").classList.toggle("flipCard")
        setTimeout(function () {
            setIsFront(!isFront)
        }, 3000)
    }
    const [people] = useState([
        {
            name: 'michael',
            url: 'SOME PICTURE 1'
        },
        {
            name: 'jon',
            url: 'SOME PICTURE 2'
        }
    ]);

    return(
        <div className="thin" style={{ height: "90vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2 style={{ color: "white" }}>Tinder-Card</h2>
            <div className="thin" style={{ height: "75vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {people.map((person, idx) => (
                // <div>
                    <div className="Card" id="Card" onClick={toggleCard}>
                        <TinderCard preventSwipe={['up','down']}>
                            <div className="CardFront">
                                <h2>{people[idx].name}</h2>
                            </div>
                            <div className="CardBack">
                                <h2>{people[idx].url}</h2>
                            </div>
                        </TinderCard>
                    </div>
                // </div>
            ))}
        </div>
        </div>
    )
}

export default MyTinder;
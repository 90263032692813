import React, { useState } from 'react';
import './OnClickMain.css';

const OnClickMain = () => {
    const data = [
        {
            cardData: "Box #1",
            cardContents: "Card #1"
        },
        {
            cardData: "Box #2",
            cardContents: "Card #2"
        },
        {
            cardData: "Box #3",
            cardContents: "Card #3"
        }
    ];
    
    const [selected, setSelected] = useState(4);
    const [isRemoved, setIsRemoved] = useState(4);

    const style = {
        width:"250px",
        height: "250px",
        backgroundColor: "lightGray",
        border: "3px solid black",
        marginBottom: "25px"
    }


    const handleMakeMain = (idx) => {
        console.log("handleMakeMain: " + idx)
        setSelected(idx);
    }

    const handleRemoveMain = (selected) => {
        setIsRemoved(selected);
    }

    const styleHandlerTitle = (idx, selected) => {
        if (idx === selected) {
            return(
                {
                    color: "yellow"
                }
            )
        }
        else {
            return(
                {
                    color: "white"
                }
            ) 
        }
    }

    const styleHandlerBox = (idx, seleted, style) => {
        if (idx === selected) {
            return(
                {
                    ...style,
                    border: "5px solid yellow"
                }
            )
        }
        else {
            return(
                {
                    ...style
                }
            )
        }
    }
    
    return(
        <div className="Wrapper">
            <div className="AllBoxContainer">
                {data.map((item, idx) => 
                    {
                        if (idx===isRemoved) {
                            return(
                                <div style={{ width: "250px", height: "250px", margin: "200px 25px 0 25px", color: "white" }}> 
                                    {console.log("map- item:"+item+" idx: "+idx)}
                                    Nothing to see here
                                </div>
                            )
                        }
                        else {
                            return(
                                <div className="BoxContainer" id={"box-container-"+idx} key={idx}>
                                    <div className="Title" id={"title-"+idx} style={styleHandlerTitle(idx,selected)}>
                                        <h3>{item.cardData}</h3>
                                    </div>
                                    <div className="Box" style={styleHandlerBox(idx, selected, style)}>
                                        {item.cardContents}
                                    </div>
                                    <div className="SelectorButton">
                                        <button 
                                            style={{ border: "3px solid gold", backgroundColor: "yellow", color: "black"}}
                                            onClick={()=>handleMakeMain(idx)}>
                                                Make Main
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    }
                )}
            </div>
            <button onClick={()=>handleRemoveMain(selected)}>Remove Main</button>
        </div>
    )
}

export default OnClickMain;
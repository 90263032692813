import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import axios from '../../axios-edeco';

import { authActions } from '../../store/auth';

import Preview from './Preview';
import SubjectScrollbarItem from './SubjectScrollbarItem';

import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Pagination,Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

import './UserHome.css';

const UserHome = () => {

    const history = useHistory();

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);

    SwiperCore.use([Pagination,Navigation, Keyboard]);

    const [SubjectsData, setSubjectsData] = useState([]);
    const [CategoriesData, setCategoriesData] = useState([]);

    const [displayCategory, setDisplayCategory] = useState(false);
    const [subjCategory, setSubjCategory] = useState([]);

    const [jsxSubjectData, setJsxSubjectData] = useState([]);

    const [allCardsRevision, setAllCardsRevision] = useState([]);
    const [allCardsNew, setAllCardsNew] = useState([]);
    const [allCardsArchived, setAllCardsArchived] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');

    const [dbNotEmpty, setDbNotEmpty] = useState(false);

    useEffect(() => {
        axios.get('/api/subject/categories',
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                } 
            })
        .then(response => {
            console.log("Categories: "+ response.data.length);
            const data = response.data
            setSubjectsData(data);
            // after getting all the subject data, put it in JSX form
            let jsxData = [];
            for (let i in response.data){
                let iTitle = response.data[i].title;
                jsxData.push(
                    [<div className="SingleSubjectScrollbar" onClick={()=>handleSubjectClick(iTitle)}>
                        {iTitle}
                    </div>]);
            }
            setJsxSubjectData(jsxData);
            if (response.data.length > 0) {
                setDbNotEmpty(true);
            }
        })

        axios.get('/api/category/flashcards',
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            } 
        })
        .then(response => {
            const data = response.data
            setCategoriesData(data);
        })

        //revision API
        .then(()=> {
            axios.get('/api/flashcard/revision',
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            } 
        })
        .then(response => {
            const data = response.data
            setAllCardsRevision(data);
        })
        })

        // new API
        .then(()=> {
            axios.get('/api/flashcard/new',
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                } 
            })
            .then(response => {
                const data = response.data
                console.log("NEW-API: " + data.length)
                setAllCardsNew(data);
            })  
        })

        // archived API
        .then(()=> {
            axios.get('/api/flashcard/archived',
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                } 
            })
            .then(response => {
                const data = response.data
                console.log("ARCHIVED-API: " + data.length)
                setAllCardsArchived(data);
            })
        })
    }, [userToken]) // this is the empty array fed to useEffect to tell it to only run once!
    
// this takes a single subject (string) as an input, filters through a set of categories, and returns only those categories whose subject matches
    const getCategories = (subject) => {
        
        let newCategories = []; // Object with (multiple) category objects inside
        console.log("nCategories: " + subject.categories.length)
        for (let i in subject.categories) {
            let iCategory = subject.categories[i];
            newCategories = newCategories.concat(iCategory.title)
        }
        console.log("getCategories: " + newCategories)
        setSubjCategory(newCategories);
    }

    const handleSubjectClick = (subj) => {
        setDisplayCategory(true);
        getCategories(subj);
    }

    const toggleEmptyAPIAlert = () => {
        let updateShowAlert = !showAlert;
        setShowAlert(updateShowAlert)
    }

    const handleRevisionClick = () => {
        console.log("handleRevisionClick: " + Object.keys(allCardsNew));
        if (allCardsRevision.length === 0) {
            setAlertTitle('Revision');
            toggleEmptyAPIAlert()
        }
        else {
            history.push({
                pathname: "/AllStudy",
                state: {
                    studyType: 'Revision',
                    cards: allCardsRevision
                }
            });
        }
        
    }

    const handleNewClick = () => {
        if (allCardsNew.length === 0) {
            setAlertTitle('New');
            toggleEmptyAPIAlert()
        }
        else {
            history.push({
                pathname: "/AllStudy",
                state: {
                    studyType: 'New',
                    cards: allCardsNew
                }
            });
        }
        
    }

    const handleArchivedClick = () => {
        if (allCardsArchived.length === 0) {
            setAlertTitle('Archived');
            toggleEmptyAPIAlert()
        }
        else {
            history.push({
                pathname: "/AllStudy",
                state: {
                    studyType: 'Archived',
                    cards: allCardsArchived
                }
            });
        }
        
    }

    const prevRef = useRef(null);
    const nextRef = useRef(null)

    return(
        <div className="UserHomeContainer">
            <div className="TextTitle">
                <h2>Study</h2>
            </div>
            <div className="UserHomeCategoriesContainer">
                <div className="UserHomeCategory" onClick={handleRevisionClick} >
                    <h4>Revision</h4>
                    <Modal centered show={showAlert} onHide={toggleEmptyAPIAlert}>
                            <Modal.Header>
                                <Modal.Title><i>Cards in {alertTitle}</i></Modal.Title>
                                <button 
                                    onClick={toggleEmptyAPIAlert}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                You currently have no cards for: {alertTitle}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={toggleEmptyAPIAlert}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
                <div className="UserHomeCategory" onClick={handleNewClick}>
                    <h4>New</h4>
                </div>
                <div className="UserHomeCategory" onClick={handleArchivedClick}>
                    <h4>Archived</h4>
                </div>
            </div>
            {!dbNotEmpty && 
                <div className="NoSubjectText">
                    No subjects available to display. 
                </div>
            }
            {dbNotEmpty && SubjectsData.length<4 && 
                    <div className="AllScrollbarItems">
                        {SubjectsData.map((subj, idx)=>(
                            <div className="SingleSubjectScrollbar" key={idx} onClick={()=>handleSubjectClick(subj)}>
                                {subj.title}
                            </div>
                        ))}
                    </div>
            }
            {dbNotEmpty && SubjectsData.length>3 &&
                <div className="SubjectScrollbarContainer">
                    <div className="Arrow" ref={prevRef}>
                        <BiLeftArrow />
                    </div>
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            loop={true}
                            navigation={{
                                prevEl: prevRef.current, 
                                nextEl: nextRef.current 
                                }}
                            keyboard={{ "enabled": true }}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {SubjectsData.map((subj, idx)=>(
                                <div className="AllScrollbarItems" key={idx}>
                                    <SwiperSlide key={idx}>
                                        <div className="SingleSubjectScrollbar" key={idx} onClick={()=>handleSubjectClick(subj)}>
                                            {subj.title}
                                        </div>
                                    </SwiperSlide>
                                </div>
                            ))}
                        </Swiper>
                    <div className="Arrow" ref={nextRef}>
                        <BiRightArrow />
                    </div>
                </div>
            }
            {displayCategory && 
                <>
                    {subjCategory.map((cat, idx) =>
                        <div className="CategoryDisplayContainer" key={idx}>
                            <Preview 
                                key={idx} 
                                superGroup={cat} 
                                subGroup={CategoriesData} 
                                subType={"flashcard"}
                            />
                        </div>
                    )}
                </>
            }
        </div>
    )
}

export default UserHome;
import React from 'react';

import Flashcard from './Flashcard';
import MySwipe from './MySwipe';
import MyDraggable from './MyDraggable';
import MyTinder from './MyTinder';
import OnClickMain from './OnClickMain';
import OnClickMainTwo from './OnClickMainTwo';
import MySwipeCard from './MySwipeCard';
import ZIndex from './ZIndex';
import MyFlippy from './MyFlippy'; 
import SliderOnClick from './SliderOnClick';

const Library = () => {
    return(
        <div className="LibraryContainer">
            <div className="CardBox" style={{ height: "100%"}}>
                <SliderOnClick />
            </div>
            <hr />
            <div className="CardBox" style={{ height: "100%"}}>
                <OnClickMainTwo />
            </div>
            <hr />

            <div className="CardBox" style={{ height: "100%"}}>
                <OnClickMain />
            </div>
            <hr />

            <div className="CardBox" style={{ height: "100%"}} >
                <Flashcard />
            </div>
            <hr />

            <div className="Z-IndexBox" style={{ height: "100vh", width: "100vw" }}>
                <ZIndex />
            </div>
            <hr />

            <div className="MySwipeCardBox" style={{ height: "100vh"}}>
                <MySwipeCard />
            </div>
            <hr />

            <div className="MyTinderBox" style={{ height: "100vh"}}>
                <MyTinder />
            </div>
            <hr />

            <div className="Box" style={{ height: "100vh"}}>
                <MyDraggable />
            </div>
            <hr />

            <div className="MySwipeBox" style={{ height: "100vh"}}>
                <MySwipe />
            </div>
            <hr />

            {/* FAILURES DOWN HERE */}
            <div className="Box" style={{ height: "100vh"}}>
                <MyFlippy />
            </div>
            <hr />

        </div>
    )
}

export default Library;
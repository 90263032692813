import React from 'react';
import TinderCard from 'react-tinder-card';

import './ZIndex.css';

const ZIndex = () => {
    // const [style, setStyle] = useState({
    //     border: "1px solid black",
    //     // position: "relative",
    //     display: "flex",
    //     width: "200px",
    //     height: "200px",
    //     backgroundColor: "red",
    //     alignItems: "center"
    // });
    //const [colorIdx, setColorIdx] = useState(0);
    //const [clickLayer1] = useState(false);
    //const numCards = 5;
    
    // const handleClickLayer1 = () => {
    //     console.log("You pressed layer1. Value: " + clickLayer1)
    // }

    const colors = ["red","orange","yellow","green","blue"];

    // const windowWidth = window.innerWidth;
    // const windowHeight= window.innerHeight;

    const handleStyles = (color, idx) => {
        return(
            {
                border: "1px solid black",
                position: "absolute",
                // left: "25px",
                // marginLeft: "-50px",
                // top: "25px",
                // marginTop: "-50px",
                width: "200px",
                height: "200px",
                backgroundColor: color,
                alignItems: "left"
            }
        )
    }

    // const handleClick = (colors, colorIdx) => {
    //     setStyle({
    //         border: "1px solid black",
    //         position: "absolute",
    //         width: "200px",
    //         height: "200px",
    //         backgroundColor: colors[colorIdx]
    //     });
    //     if (colorIdx === 4) {
    //         setColorIdx(0);
    //     }
    //     else {
    //         setColorIdx(colorIdx+1);
    //     }
    // }

    return(
        <>
            <div className="Zcontainer">
                {colors.map((color, idx)=>
                    <TinderCard>
                        {console.log(handleStyles(color,idx))}
                        <div className="Layer" style={handleStyles(color, idx)} >
                            Layer {idx+1}
                        </div>
                    </TinderCard>
                )}
            </div>
        </>
        // Notes: onClick={()=>handleClick(colors, colorIdx)}
    )
}

export default ZIndex;
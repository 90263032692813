import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Form, Modal } from 'react-bootstrap';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import './Flashcards.css';
import axios from '../../axios-edeco';

const Flashcards = (props) => {

    const flipRef = useRef(false);
    const buttonRef = useRef(false);

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);

    const history = useHistory();

    const [showFinished, setShowFinished] = useState(false);
    const [isLoadingFinished, setIsLoadingFinished] = useState(false);

    const [isRemoved, setIsRemoved] = useState([])
    const [isReversed, setIsReversed] = useState(false)
    const [currentIdx, setCurrentIdx] = useState(0)
    const [toggles, setToggles] = useState({})

    const card_data = props.location.state.flashcards;
    const total_cards = card_data.length;
    const percent_complete = currentIdx/total_cards;

    useEffect (() => {
        let initState = [];
        for (let i=0;i<total_cards;i++) {
            let iState = {
                id: i,
                ref: React.createRef()
            }
            initState = initState.concat(iState);
        }
        console.log(initState)
        setToggles(initState)
    }, [])

    const toggleShowFinished = () => {
        let updateShowFinished = !showFinished;
        setShowFinished(updateShowFinished)
    };

    const handleFlip = (currentIdx) => {
        setIsReversed(!isReversed);
        toggles[currentIdx].current.toggle();
    }

    const handlePlus = () => {
        if(currentIdx === total_cards-1) {

        }
        else {
            let updateCurrentIdx = currentIdx+1;
            console.log("handlePlus - idx: " + updateCurrentIdx);
            setCurrentIdx(updateCurrentIdx);
        }
    }
    const handleMinus = () => {
        if (currentIdx === 0) {

        }
        else {
            let updateCurrentIdx = currentIdx-1;
            console.log("handleMinus - idx: " + updateCurrentIdx);
            setCurrentIdx(updateCurrentIdx);
        }
    }

    const handleInct = () => {
        let updateIsRemoved = isRemoved.concat(currentIdx);
        let updateCurrentIdx = currentIdx + 1;
        let updateIsReversed = !isReversed;
        let currentPK = card_data[currentIdx].id;
        setIsRemoved(updateIsRemoved);
        setIsReversed(updateIsReversed);
        setCurrentIdx(updateCurrentIdx);
        axios.get('/api/flashcard/' + currentPK + '/incorrect', 
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            }
        })
        .then(response=> {
            console.log('Crct for pk: ' + currentPK)
        });
    }

    const handleCrct = () => {
        let updateIsRemoved = isRemoved.concat(currentIdx);
        let updateCurrentIdx = currentIdx + 1;
        let updateIsReversed = !isReversed;
        let currentPK = card_data[currentIdx].id;
        setIsRemoved(updateIsRemoved);
        setIsReversed(updateIsReversed);
        setCurrentIdx(updateCurrentIdx);
        console.log("FFF: " + currentPK)
        let d = '/api/flashcard/' + currentPK + '/correct'
        console.log('d ' + d)
        axios.get('/api/flashcard/' + currentPK + '/correct', 
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            }
        })
        .then(response=> {
            console.log('Crct for pk: ' + currentPK)
        });
    }

    const handleFinish = () => {
        setIsLoadingFinished(true);
        setTimeout(() => {
            setIsLoadingFinished(false);
            history.push('/UserHome');
        }, 2000)
    };

    const cardStyle = (idx) => { 
        return(
            {
                // backgroundColor: "lightGray",
                color: "black",
                width: '350px', 
                height: '275px', 
                margin: "25px 5px", 
                position: "absolute",
                top: "0px",
                left: "-180px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "left",
                zIndex: total_cards-idx
            }
        )
    }

    const innerProgStyle = () => {
        return(
            {
                width: percent_complete*500,
                height: "25px",
                position: "absolute",
                left: "25%",
                zIndex: "1",
                backgroundColor: "lime",
                borderRadius: "10px",
            }
        )
    } 

    const outerProgStyle = () => {
        return(
            {
                width: "500px",
                height: "25px",
                position: "absolute",
                left: "25%",
                zIndex: "2",
                border: "3px solid black",
                borderRadius: "10px",
            }
        )
    }   
    // props.location.state.STATE_VARIABLE is passed from Category.js. Need to lock Flashcard.js using isAuth
    // props.location.state.categoryID = Category PK
    // props.location.state.flashcards
    return(
        <div className="FlashcardsContainer">
            <div className="CategoryTitle">
                Revision
                <hr />
            </div>
            <div className="ProgressBarContainer">
                <div className="ProgressText">
                    {Math.ceil(percent_complete*100)}%
                </div>
                <div className="ProgressVisual">
                    <div className="OuterProgressBar" style={outerProgStyle()}>{' '}</div>
                    <div className="InnerProgressBar" style={innerProgStyle()}></div>
                </div>
            </div>
            <div className="CardsContainer">
                {currentIdx === card_data.length &&
                    <Modal centered show={true} onHide={toggleShowFinished}>
                    <Modal.Header>
                        <Modal.Title><i>Section Complete!</i></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="AddFormContainer">
                            <div>
                                Study complete for this section!
                            </div>
                            
                            <div style={{ margin: "25px 0px 0px 0px"}}>
                                {isLoadingFinished && 
                                    <span> Redirecting... </span>
                                }
                            </div>    
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{
                        display: "flex",
                        justifyContent: "center",
                        }}>
                    <div className="LoadingContainer1" style={{ padding: "0 0 0 0"}}>
                        {isLoadingFinished && 
                            <div className="loading">
                                    <>
                                    <div className="arc"></div>
                                    <div className="arc"></div>
                                    <div className="arc"></div>
                                    </>
                            </div>
                        }
                        {!isLoadingFinished &&
                         <div>
                         <Button className="FinishButton" centered variant="success" onClick={handleFinish} >
                             Finish
                         </Button>
                     </div>
                        }
                    </div>
                    </Modal.Footer>
                </Modal>
                }
                {card_data.map((card,idx) => 
                    {
                        if(isRemoved.includes(idx)) {
                            
                        }
                        else{
                            return(
                                <div key={idx} style={{ position: "relative" }}>
                                <Flippy
                                    // key={idx}
                                    flipOnHover={false}
                                    flipOnClick={true} 
                                    flipDirection="horizontal"
                                    isFlipped={false}
                                    ref={toggles[idx]} // this ref is in STATE toggles @ index and ref is a key
                                    style={cardStyle(idx)} /// these are optional style, it is not necessary
                                >
                                    <FrontSide style={{ background: "lightGray", border: "2px solid black", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    {/* <FrontSide style={cardStyle(idx)}>  */}
                                        FrontSide
                                        <br/>
                                        Index: {idx}
                                        <br/>
                                        Z-Index: {total_cards-idx}
                                        <br/>
                                        {card.markdown}
                                        <br/>
                                    
                                    </FrontSide>
                                    <BackSide style={{ background: "Gray", border: "2px solid black", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    {/* <BackSide style={cardStyle(idx)}> */}
                                        BackSide
                                        <br/>
                                        Index: {idx}
                                        <br/>
                                        Z-Index: {total_cards-idx}
                                        <br/>
                                        {card.markdown}
                                    </BackSide>
                                </Flippy>
                                </div>
                            )
                        }
                    }
                )}
            </div>
            <div className="ButtonsContainer">
                {isReversed && <button onClick={()=>handleInct(currentIdx)}>Incorrect</button>}
                <button onClick={()=>handleFlip(currentIdx)}>
                    Flip
                </button>
                {isReversed && <button onClick={()=>handleCrct(currentIdx)}>Correct</button>}
            </div>
            {/* <div>
                <button onClick={handleMinus}>-</button>
                <button onClick={handlePlus}>+</button>
            </div> */}
        </div>
    )
}

export default Flashcards;
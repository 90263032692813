import React, { useState } from 'react';

import './OneCategory.css';

const OneCategory = (props) => {

    const [hover, setHover] = useState(false);
    const [clickedInDiv, setClickedInDiv] = useState(0);

    const catPK = props.catInfo.id;
    const catTitle = props.catInfo.title;
    const selectedPKs = props.PKs;
    const toSelect = props.toSelect

    const selectedStyle = {
        border: "3.5px solid yellow"
    }

    const notSelectedStyle = {
        borderColor: "black"
    }

    const handleMouseOver = () => {
        setHover(true);
    }

    const handleMouseLeave = () => {
        setHover(false);
        setClickedInDiv(0);
    }

    const handleClick = (pk) => {
        let updateClickedInDiv = clickedInDiv + 1;
        setClickedInDiv(updateClickedInDiv);
        toSelect(catPK);
    }

    return(
        <div className="IndividualCategoriesCard" 
             onClick={handleClick}
             onMouseOver={handleMouseOver} 
             onMouseLeave={handleMouseLeave}
             style={selectedPKs.includes(catPK) ? selectedStyle    
                : hover&&clickedInDiv>0 ? notSelectedStyle  
                : hover ? selectedStyle
                : notSelectedStyle }
        >
            {catTitle}
        </div>
    )
}

export default OneCategory;
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import axios from '../../axios-edeco';

import { Button, Modal } from 'react-bootstrap';
import './Category.css';

const Category = (props) => {

    let history = useHistory();
    
    const location = useLocation();
    const { category } = location.state;

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const userToken = useSelector((state) => state.auth.token);

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedCategoryID, setSelectedCategoryID] = useState([]);
    const [selectedCategoryTitle, setSelectedCategoryTitle] = useState([]);
    const [selectedCategoryCards, setSelectedCategoryCards] = useState([]);

    const [cardsRevision, setCardsRevision] = useState([]);
    const [cardsNew, setCardsNew] = useState([]);
    const [cardsArchived, setCardsArchived] = useState([]);

    const [showRevisionAlert, setShowRevisionAlert] = useState(false);
    const [showNewAlert, setShowNewAlert ] = useState(false);
    const [showArchivedAlert, setShowArchivedAlert] = useState(false);

    const toggleShowRevisionAlert = () => setShowRevisionAlert(!showRevisionAlert)
    const toggleShowNewAlert = () => setShowNewAlert(!showNewAlert)
    const toggleShowArchivedAlert = () => setShowArchivedAlert(!showArchivedAlert)
    // PK id = selectedCategory[0].id
    // don't need to re-hit APIs, but may need to pass the PK to the next function
    const handleRevisionClick = () => {
        if (cardsRevision.length === 0) {
            toggleShowRevisionAlert();
        }
        else {
            history.push({
                pathname: '/Flashcards',
                state: { 
                    categoryID: selectedCategoryID,
                    flashcards: cardsRevision
                    }
            })
        }
    }

    const handleNewClick = () => {
        if (cardsNew.length === 0) {
            toggleShowNewAlert();
        }
        else {
            history.push({
                pathname: '/Flashcards',
                state: { 
                    categoryID: selectedCategoryID,
                    flashcards: cardsNew
                    }
            })
        }
    }

    const handleArchivedClick = () => {
        if (cardsArchived.length === 0) {
            toggleShowArchivedAlert(); 
        }
        else {
            history.push({
                pathname: '/Flashcards',
                state: { 
                    categoryID: selectedCategoryID,
                    flashcards: cardsArchived
                    }
            })
        }
    }

    //2021/08/03 - MB - Call the API for the flashcards in all categories, then loop through and only return ones relevant to the selected category
    //2021/08/04 - MB - Change this (sometime soon) so that it is done by PK, not by name
    useEffect(() => {
        axios.get('/api/category/flashcards',
        { headers: 
            { 
                'Authorization': 'Token ' + userToken
            } 
        })
        .then(response => {
            const data = response.data;
            let selectedCategoryData = [];
            let catCounter = 0;
            console.log("Finished pinging" + data.length)
            for (let i in data) {
                let iCat = data[i]
                if (iCat.title === category) {
                    selectedCategoryData = selectedCategoryData.concat(iCat);
                }
                else {
                    catCounter = catCounter + 1;
                }
            }
            setSelectedCategory(selectedCategoryData);
            setSelectedCategoryID(selectedCategoryData[0].id);
            setSelectedCategoryTitle(selectedCategoryData[0].title);
            setSelectedCategoryCards(selectedCategoryData[0].flash_cards);

            // GET REVISION
            axios.get('/api/category/'+selectedCategoryData[0].id+'/revision',
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                } 
            })
            .then(response => {
                setCardsRevision(response.data)
            })
            // GET NEW
            axios.get('/api/category/'+selectedCategoryData[0].id+'/new',
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                } 
            })
            .then(response => {
                setCardsNew(response.data)
            })
            // GET ARCHIVED
            axios.get('/api/category/'+selectedCategoryData[0].id+'/archived',
            { headers: 
                { 
                    'Authorization': 'Token ' + userToken
                } 
            })
            .then(response => {
                setCardsArchived(response.data)
            })
        })
    }, [])

    return(
        <div className="CategoryContainer">
            {console.log("Selected Category Cards: " + selectedCategoryCards)}
            <div className="CategoryTitle">
                {category}
                <hr />
            </div>
            <div className="CategoryGeneralContainer">
                <div className="CategoryDisplay">
                    <div className="CategoryDisplayContainerBig">
                        
                    </div>
                    <div className="CategoryDisplayContainerSmall">
                        <div className="CategoryDisplaySmall">

                        </div>
                        <div className="CategoryDisplaySmall">

                        </div>
                        <div className="CategoryDisplaySmall">

                        </div>
                        <div className="CategoryDisplaySmall">

                        </div>
                        <div className="CategoryDisplaySmall">

                        </div>
                    </div>
                </div>
                <div className="CategoryFunctionButtons">
                    {/* <Link to={{
                        pathname: '/Flashcards',
                        state: { 
                            categoryID: selectedCategoryID,
                            flashcards: selectedCategoryCards
                            }
                    }}>
                    <button>Revision</button>
                    </Link> */}
                    <button onClick={handleRevisionClick}>Revision</button>
                        <Modal centered show={showRevisionAlert} onHide={toggleShowRevisionAlert}>
                            <Modal.Header>
                                <Modal.Title><i>Cards for Revision</i></Modal.Title>
                                <button 
                                    onClick={toggleShowRevisionAlert}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                You currently have nothing to review in the category:  
                                {' ' + selectedCategoryTitle}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={toggleShowRevisionAlert}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        
                    <button onClick={handleNewClick}>New</button>
                        <Modal centered show={showNewAlert} onHide={toggleShowNewAlert}>
                            <Modal.Header>
                                <Modal.Title><i>New Cards</i></Modal.Title>
                                <button 
                                    onClick={toggleShowNewAlert}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                You currently have nothing new in the category: 
                                {' ' + selectedCategoryTitle}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={toggleShowNewAlert}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>

                    <button onClick={handleArchivedClick}>Archived</button>
                        <Modal centered show={showArchivedAlert} onHide={toggleShowArchivedAlert}>
                            <Modal.Header>
                                <Modal.Title><i>Archived Cards</i></Modal.Title>
                                <button 
                                    onClick={toggleShowArchivedAlert}
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal" 
                                    aria-label="Close">
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                You currently have nothing archived in the category: 
                                {' ' + selectedCategoryTitle}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={toggleShowArchivedAlert}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </div>
            </div>
            <div className="FlashcardGeneralContainer">
                <div className="FlashcardTitle">
                    Flashcards
                    <hr />
                </div>
                <div className="FlashcardFunctions">
                    <div className="FlashcardButtons">
                        <button>Add</button>
                        <button>Remove</button>
                    </div>
                </div>
                <div className="FlashcardDisplayContainer">
                    {selectedCategoryCards.map((card, idx) => 
                        <div className="Flashcard" key={idx}>
                            {card.markdown}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Category;
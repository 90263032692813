import './App.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from './axios-edeco';

import Header from './components/Header/Header';
// import Flashcard from './components/Flashcard/Flashcard';
// import MySwipe from './components/Flashcard/MySwipe';
// import MyDraggable from './components/Flashcard/MyDraggable';
// import MyTinder from './components/Flashcard/MyTinder';
// import OnClickMain from './components/Flashcard/OnClickMain';
// import OnClickMainTwo from './components/Flashcard/OnClickMainTwo';
// import MySwipeCard from './components/Flashcard/MySwipeCard';
// import ZIndex from './components/Flashcard/ZIndex';
// import MyFlippy from './components/Flashcard/MyFlippy'; 

import { authActions } from './store/auth';

import Home from './components/Home/Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Donate from './components/Donate/Donate';
import Login from './components/Login/Login';
import Library from './components/Flashcard/Library';
import UserProfile from './components/UserProfile/UserProfile';
import Study from './components/Study/Study';
import Subjects from './components/Subjects/Subjects';
import Category from './components/Category/Category';
import UserHome from './components/UserHome/UserHome';
import AddSubject from './components/AddSubject/AddSubject';
import Flashcards from './components/Flashcards/Flashcards';
import AllStudy from './components/AllStudy/AllStudy';
import Categories from './components/Categories/Categories';

function App() {

    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);

    const loginHandler = (event) => {
      event.preventDefault();
  
      dispatch(authActions.login())
    };

    const getAPIs = () => {
        axios.get('/api/category/all')
        .then(response => {
            const data = response.data
            console.log("getAPIs: " + data)
        })
    }
    
    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                {/* <div className="BodyDiv"> */}
                    <Switch>
                        {isAuth && <Route path="/UserProfile" component={UserProfile} />}
                        {isAuth && <Route path="/Subjects" component={Subjects} /> }
                        {isAuth && <Route path="/UserHome" component={UserHome} /> }
                        {isAuth && <Route path="/Categories" component={Categories} /> }
                        {isAuth && <Route path="/Category" component={Category} /> }
                        {isAuth && <Route path="/Study" component={Study} /> }
                        {isAuth && <Route path="/AddSubject" component={AddSubject} /> }
                        {isAuth && <Route path="/AllStudy" component={AllStudy} /> }
                        {/* {isAuth && <Route path="/Flashcards" components={Flashcards} /> } */}
                        {/* alternative is to allow access, but if not logged in, forward to login page, etc. */}
                        <Route path="/Flashcards" component={Flashcards} />
                        <Route path="/Library" component={Library} />
                        <Route path="/Donate" component={Donate} />
                        <Route path="/Login" component={Login} />
                        <Route path="/" component={Home} />
                    </Switch>
                {/* </div> */}
                
            </BrowserRouter>
        </div>    
    );
}

export default App;

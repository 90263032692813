import React from 'react';

import './UserProfile.css';

const UserProfile = () => {
    return(
        <div className="UserProfileContainer">
            <div className="TextTitle">
                <h2>User Profile</h2>
            </div>
        </div>
    )
}

export default UserProfile;
import React from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import TinderCard from 'react-tinder-card';

import './MyFlippy.css';

const MyFlippy = () => {
    return(
        <div className="FlippyCard">
            <h2 style={{ color: "white" }}>React-Flippy</h2>
            <p style={{ color: "white", margin: "10px 0px" }}>React-Flippy and Tinder Card animations clash</p>
            <div className="DemoContainer">
                <div className="DemoCol"> 
                    {/* Card 1 - Normal React Flippy*/}
                    <p style={{ color: "white", margin: "10px 0px" }}>Normal React Flippy Card</p>
                    <Flippy
                        flipOnHover={false} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        //ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '200px', height: '200px', margin: "25px 75px" }} /// these are optional style, it is not necessary
                    >
                        <FrontSide style={{ background: "lightBlue", border: "2px solid black" }}>
                            FrontSide
                        </FrontSide>
                        <BackSide style={{ background: "lightGray", border: "2px solid black" }}>
                            BackSide
                        </BackSide>
                    </Flippy>
                </div>

                <div className="DemoCol"> 
                    <p style={{ color: "white", margin: "10px 0px" }}>Flippy Card + Tinder Card</p>
                    {/* Card 2 - Backside is a Tinder Card*/}
                    <Flippy
                        flipOnHover={false} // default false
                        flipOnClick={true} // default false
                        flipDirection="horizontal" // horizontal or vertical
                        //ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
                        // if you pass isFlipped prop component will be controlled component.
                        // and other props, which will go to div
                        style={{ width: '200px', height: '200px', margin: "25px 75px" }} /// these are optional style, it is not necessary
                    >
                        <FrontSide style={{ background: "lightBlue", border: "2px solid black" }}>
                            FrontSide
                        </FrontSide>
                        <TinderCard style={{ background: "lightGray", border: "2px solid black" }}>
                        <BackSide style={{ background: "lightGray", border: "2px solid black" }}>
                            BackSide
                        </BackSide>
                        </TinderCard>
                    </Flippy>
                </div>
            </div>
        </div>
    )
}

export default MyFlippy;
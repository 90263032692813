import React, { useState, useEffect } from 'react';
import axios from '../../axios-edeco';

import Preview from './Preview';

import './Study.css';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import SubjectScrollbarItem from './SubjectScrollbarItem';


const Study = () => {

    const [SubjectsData, setSubjectsData] = useState([]);
    const [CategoriesData, setCategoriesData] = useState([]);

    const [displayCategory, setDisplayCategory] = useState(false);
    const [subjCategory, setSubjCategory] = useState([])

    useEffect(() => {
        axios.get('/api/subject/categories')
        .then(response => {
            const data = response.data
            setSubjectsData(data);
        })

        axios.get('/api/category/flashcards')
        .then(response => {
            const data = response.data
            setCategoriesData(data);
        })

    }, []) // this is the empty array fed to useEffect to tell it to only run once!
    
// this takes a single subject (string) as an input, filters through a set of categories, and returns only those categories whose subject matches
    const getCategories = (subject) => {
        
        let newCategories = []; // Object with (multiple) category objects inside
        console.log("nCategories: " + subject.categories.length)
        for (let i in subject.categories) {
            let iCategory = subject.categories[i];
            newCategories = newCategories.concat(iCategory.title)
        }
        console.log("getCategories: " + newCategories)
        setSubjCategory(newCategories);
    }

    const handleSubjectClick = (subj) => {
        setDisplayCategory(true);
        getCategories(subj);
    }

    return(
        <div className="StudyContainer">
            <div className="TextTitle">
                <h2>Study</h2>
            </div>
            <div className="StudyCategoriesContainer">
                <div className="StudyCategory">
                    <h4>Revision</h4>
                </div>
                <div className="StudyCategory">
                    <h4>New</h4>
                </div>
                <div className="StudyCategory">
                    <h4>Archived</h4>
                </div>
            </div>
            <div className="SubjectScrollbarContainer">
                <div className="Arrow">
                    <BiLeftArrow />
                </div>
                {SubjectsData.map((subj, idx) => (
                    <SubjectScrollbarItem 
                        subject={subj.title} 
                        display={()=>handleSubjectClick(subj)} 
                        key={idx} />
                ))}
                <div className="Arrow">
                    <BiRightArrow />
                </div>
            </div>
            {displayCategory && 
                <>
                    {subjCategory.map((cat, idx) =>
                        <div className="CategoryDisplayContainer" key={idx}>
                            <Preview key={idx} superGroup={cat} subGroup={CategoriesData} subType={"flashcard"}/>
                        </div>
                    )}
                </>
            }
        </div>
    )
}

export default Study;